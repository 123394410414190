import { HTMLAttributes } from 'react';
import { CompanyService, LocationService, Service } from '../../../interfaces';
import {
  ARR_SERVICE_ORDER,
  ARR_SLIDE_TEMPLATE,
  SLIDE_STYLE,
} from '../../../constant/LocationMonthlyReviewPowerpoint';

type Id = string | number;

enum Column {
  Current = 'current',
  Unused = 'unused',
}

type SlideContent = {
  headerText: string;
  locationId: number | null;
  serviceId: number | null;
  groupName: string | null;
};

type Task = {
  id: Id;
  columnId: Id;
  content: SlideContent;
  hidden?: boolean;
};

type SlideProps = HTMLAttributes<HTMLDivElement> & {
  withOpacity?: boolean;
  isDragging?: boolean;
  slide: Task;
  onSelect?: (event: React.MouseEvent<HTMLDivElement>) => void;
  selected: boolean | undefined;
  count: number;
  mapLocationNameByLocationId: Map<number, string>;
  mapServiceNameByServiceId: Map<number, string>;
};

type SlideType = {
  id: Id;
  columnId: Column;
  content: {
    headerText: string;
    locationId: number | null;
    serviceId: number | null;
    groupName: string | null;
  };
  hidden?: boolean;
};

type Container = {
  arrSlide: Task[];
  count: number;
  columnId: Column;
  arrSelectedSlideId: string[];
  selectSlide: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => void;
  mapLocationNameByLocationId: Map<number, string>;
  mapServiceNameByServiceId: Map<number, string>;
};

/**
 * This function iterates through arrCompanyService to retrieve validRestaurantService
 * While retrieving restaurantValidLocationService, also retrieve mapLocationNameByLocationId
 * and mapServiceNameByServiceId for rendering of drag and drop sort component
 * @param arrCompanyService - Array of company service
 * @param restaurantId - Restaurant id to retrieve restaurantValidLocationService for
 * @returns restaurantService - restaurantService with only valid locations and services
 * @returns mapLocationNameByLocationId - Map of location name by location id
 * @returns mapServiceNameByServiceId - Map of service name by service id
 */
const getValidRestaurantServiceFromArrCompanyService = (
  arrCompanyService: Array<CompanyService>,
  restaurantId: number
) => {
  const mapLocationNameByLocationId = new Map<number, string>();
  const mapServiceNameByServiceId = new Map<number, string>();
  for (let companyIndex = 0; companyIndex < arrCompanyService.length; companyIndex += 1) {
    const { arrRestaurantService } = arrCompanyService[companyIndex];
    for (
      let restaurantIndex = 0;
      restaurantIndex < arrRestaurantService.length;
      restaurantIndex += 1
    ) {
      const restaurantService = arrRestaurantService[restaurantIndex];
      if (restaurantId === restaurantService.restaurantId) {
        const arrValidLocationService: Array<LocationService> = [];
        restaurantService.arrLocationService.forEach((locationService) => {
          let hasAtLeastOneValidService = false;
          const { name: locationName, locationId, arrService } = locationService;
          const arrValidService: Array<Service> = [];
          arrService.forEach((service) => {
            const { name: serviceName, serviceId, isValid, startDate, serviceType } = service;
            if (isValid === true && startDate !== null && serviceType.type !== 'Placeholder') {
              mapServiceNameByServiceId.set(serviceId, serviceName);
              hasAtLeastOneValidService = true;
              arrValidService.push(service);
            }
          });
          locationService.arrService = arrValidService;
          if (hasAtLeastOneValidService) {
            mapLocationNameByLocationId.set(locationId, locationName);
            arrValidLocationService.push(locationService);
          }
        });
        restaurantService.arrLocationService = arrValidLocationService;
        return { restaurantService, mapLocationNameByLocationId, mapServiceNameByServiceId };
      }
    }
  }
};

/**
 * This function utilises validRestaurantService to generate the default list of slides
 * applicable for the selected restaurant id
 * @param arrCompanyService - Array of company service
 * @param restaurantId - Restaurant id to get property monthly review slide metadata for
 * @returns defaultPropertyMonthlyReviewSlideMetadata - Default property monthly review
 * slide metadata
 * @returns mapLocationNameByLocationId - Map of location name by location id
 * @returns mapServiceNameByServiceId - Map of service name by service id
 */
const getDefaultPropertyMonthlyReviewSlideMetadata = (
  arrCompanyService: Array<CompanyService>,
  restaurantId: number
) => {
  const { restaurantService, mapLocationNameByLocationId, mapServiceNameByServiceId } =
    getValidRestaurantServiceFromArrCompanyService(arrCompanyService, restaurantId)!;
  if (!restaurantService) return;
  // Initilise template array with opening slide
  const defaultPropertyMonthlyReviewSlideMetadata: Array<SlideContent> = [];
  restaurantService.arrLocationService.forEach((locationService) => {
    const { locationId, arrService } = locationService;
    sortByNamePriority(arrService);
    ARR_SLIDE_TEMPLATE.forEach((slide) => {
      if (slide.slideStyle === SLIDE_STYLE.section) {
        defaultPropertyMonthlyReviewSlideMetadata.push({
          headerText: 'Section Slide',
          locationId,
          serviceId: null,
          groupName: null,
        });
      } else if (slide.arrSlideProp) {
        slide.arrSlideProp!.forEach((locationSlideProp) => {
          if (!locationSlideProp.arrSlideProp) {
            defaultPropertyMonthlyReviewSlideMetadata.push({
              headerText: locationSlideProp.headerText!,
              locationId,
              serviceId: -1,
              groupName: null,
            });
          } else {
            const setGroupName = new Set();
            arrService.forEach((service) => {
              const { serviceId, group } = service;
              if (setGroupName.has(group)) return;
              locationSlideProp.arrSlideProp!.forEach((serviceSlideProp) => {
                if (!locationSlideProp.grouped || !group) {
                  defaultPropertyMonthlyReviewSlideMetadata.push({
                    headerText: serviceSlideProp.headerText!,
                    locationId,
                    serviceId,
                    groupName: null,
                  });
                } else {
                  defaultPropertyMonthlyReviewSlideMetadata.push({
                    headerText: serviceSlideProp.headerText!,
                    locationId,
                    serviceId: null,
                    groupName: group,
                  });
                  setGroupName.add(group);
                }
              });
            });
          }
        });
      }
    });
  });
  return {
    defaultPropertyMonthlyReviewSlideMetadata,
    mapLocationNameByLocationId,
    mapServiceNameByServiceId,
  };
};

/**
 * This function sorts array by name by the order specified above in ARR_SERVICE_ORDER. The array is modified directly.
 * @param arrElement - Array of objects containing the 'name' property
 */
const sortByNamePriority = (arrElement: Array<{ name: string }>) => {
  arrElement.sort((elementA, elementB) => {
    const serviceAIndex = ARR_SERVICE_ORDER.indexOf(elementA.name.toLowerCase());
    const serviceBIndex = ARR_SERVICE_ORDER.indexOf(elementB.name.toLowerCase());
    return (
      (serviceAIndex > -1 ? serviceAIndex : Infinity) -
      (serviceBIndex > -1 ? serviceBIndex : Infinity)
    );
  });
};

const BAR_CHART_COLORS_FOR_ALL_SERVICES: { [key: string]: { baseline: string; default: string } } =
  {
    'all day': {
      baseline: '#d37890',
      default: '#fc8eac',
    },
    'all day leftover': {
      baseline: '#967196',
      default: '#C594C5',
    },
    'all day dining': {
      baseline: '#71c010',
      default: '#85e113',
    },
    'all day dining leftover': {
      baseline: '#789e75',
      default: '#98C894',
    },
    'all day buffet': {
      baseline: '#4ebdd4',
      default: '#5ad7f1',
    },
    'all day buffet leftover': {
      baseline: '#537ca6',
      default: '#6699CC',
    },
    breakfast: {
      baseline: '#4a9396',
      default: '#5FB3B3',
    },
    'breakfast buffet': {
      baseline: '#43668f',
      default: '#5784BA',
    },
    'breakfast buffet leftover': {
      baseline: '#00a3b9',
      default: '#00cae6',
    },
    'breakfast buffet leftovers': {
      baseline: '#4fa0c3',
      default: '#62c6f1',
    },
    'breakfast banquet': {
      baseline: '#754eae',
      default: '#8e5ed4',
    },
    'breakfast leftovers': {
      baseline: '#8b81c9',
      default: '#a195e7',
    },
    'breakfast leftover': {
      baseline: '#b35ccb',
      default: '#d368f0',
    },
    'food to charity': {
      baseline: '#805865',
      default: '#A57283',
    },
    'breakfast food to charity': {
      baseline: '#1F7A6D',
      default: '#2A9D8F',
    },
    'food to donation': {
      baseline: '#E89C9F',
      default: '#FFB6B9',
    },
    'f2c breakfast': {
      baseline: '#6fb0b6',
      default: '#8ddfe6',
    },
    'f2sc breakfast': {
      baseline: '#2A7EB5',
      default: '#3498DB',
    },
    'food to staff canteen': {
      baseline: '#8d9cae',
      default: '#a8bacf',
    },
    'rework breakfast': {
      baseline: '#4764d1',
      default: '#5377fc',
    },
    'am coffee break': {
      baseline: '#a84c82',
      default: '#c45998',
    },
    brunch: {
      baseline: '#d1ccb9',
      default: '#f0ead6',
    },
    'brunch buffet': {
      baseline: '#ae4563',
      default: '#de527a',
    },
    'brunch buffet leftover': {
      baseline: '#E9967A',
      default: '#FFA07A',
    },
    'sunday brunch': {
      baseline: '#d96055',
      default: '#FF6F61',
    },
    'sunday brunch leftover': {
      baseline: '#89b623',
      default: '#a5db2b',
    },
    'sunday brunch buffet leftover': {
      baseline: '#36ad69',
      default: '#2ECC71',
    },
    lunch: {
      baseline: '#d47b4a',
      default: '#FA9157',
    },
    'lunch buffet': {
      baseline: '#be5e3d',
      default: '#f97a4f',
    },
    'lunch buffet leftover': {
      baseline: '#c24e54',
      default: '#EC5F67',
    },
    'lunch banquet': {
      baseline: '#D88C47',
      default: '#F4A261',
    },
    'lunch banquet leftover': {
      baseline: '#b43a3a',
      default: '#d64545',
    },
    'lunch and dinner': {
      baseline: '#d9b947',
      default: '#fbd652',
    },
    'f2c lunch': {
      baseline: '#d14326',
      default: '#fb502e',
    },
    'f2sc lunch': {
      baseline: '#cc5a6f',
      default: '#f16a83',
    },
    'rework lunch': {
      baseline: '#c17d3e',
      default: '#ee9a4d',
    },
    'afternoon tea': {
      baseline: '#bc59b6',
      default: '#e66dde',
    },
    'afternoon tea leftover': {
      baseline: '#cb8b9f',
      default: '#f6a8c1',
    },
    'pm coffee break': {
      baseline: '#7688A9',
      default: '#92A8D1',
    },
    tea: {
      baseline: '#a6d497',
      default: '#bdf1ac',
    },
    dinner: {
      baseline: '#d4a953',
      default: '#FAC862',
    },
    'dinner buffet': {
      baseline: '#2bcb9f',
      default: '#34f4bf',
    },
    'dinner buffet leftover': {
      baseline: '#639aa5',
      default: '#80c4d1',
    },
    'dinner banquet': {
      baseline: '#4a984f',
      default: '#5dbb63',
    },
    'dinner banquet leftover': {
      baseline: '#b0c336',
      default: '#d3eb3d',
    },
    'Dinner food to charity': {
      baseline: '#7bb195',
      default: '#9FE2BF',
    },
    'f2c dinner': {
      baseline: '#6D8C3A',
      default: '#9bcf4c',
    },
    'f2sc dinner': {
      baseline: '#ccc365',
      default: '#fff380',
    },
    'rework dinner': {
      baseline: '#cb9009',
      default: '#f4ad0b',
    },
    supper: {
      baseline: '#66c95f',
      default: '#77e96e',
    },
    production: {
      baseline: '#d1b98c',
      default: '#f1d6a2',
    },
    'spoilt inventory': {
      baseline: '#98573c',
      default: '#c06e4b',
    },
    'delivery supply': {
      baseline: '#808080',
      default: '#C0C0C0',
    },
    'option 1': {
      baseline: '#d09c04',
      default: '#FFBF00',
    },
    'option 2': {
      baseline: '#8a6151',
      default: '#AC7966',
    },
    'option 3': {
      baseline: '#a46ec4',
      default: '#c081e6',
    },
    'option 4': {
      baseline: '#5277c9',
      default: '#6693F5',
    },
    'option 5': {
      baseline: '#00b90a',
      default: '#00e30d',
    },
  };

export type { Container, SlideContent, SlideProps, SlideType };
export {
  BAR_CHART_COLORS_FOR_ALL_SERVICES,
  Column,
  getDefaultPropertyMonthlyReviewSlideMetadata,
  sortByNamePriority,
};
