import { FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Slide from './Slide';
import { SlideProps } from './helper';

const SortableSlide: FC<SlideProps> = (props) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.slide.id,
    data: {
      type: 'Slide',
      slide: props.slide,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Slide
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      {...props}
      {...attributes}
      {...listeners}
    />
  );
};

export default SortableSlide;
