import axios from 'axios';
import { useContext, useEffect, useState } from 'react';

import Alert from './alerts/Alert';
import CompanyList from './CompanyList';
import SearchBar from '../Common/SearchBar';
import CompanyServiceInputCardsContainer from './InputComponents/CompanyServiceInputCardsContainer';
import AddCompanyButton from '../Common/Buttons/AddCompanyButton';
import { COMPANY_SERVICE_INPUT_MODE } from '../../constant/General';
import CompanyContext from '../../context/CompanyContext';
import InputCardsContext from '../../context/InputCardsContext';
import { CompanyLocationOption } from '../../interfaces';

const CompanyManagementPage = () => {
  const companyContext = useContext(CompanyContext);
  const inputCardsContext = useContext(InputCardsContext);

  const [arrAlertMessage, setArrAlertMessage] = useState<Array<any>>([]);
  const [alertHeader, setAlertHeader] = useState('');
  const [isAlert, setIsAlert] = useState(false);

  const [idStringToScrollTo, setIdStringToScrollTo] = useState<string>('');

  const fetchCompanyData = async () => {
    try {
      const response = await axios.post('/company-management/fetch-company-service-details');
      companyContext.addArrCompanyService(response.data.arrCompanyService);
      companyContext.updateArrServiceTypeOption(response.data.arrServiceType);
    } catch (error) {
      setArrAlertMessage([error]);
      setAlertHeader('Fetch companies error!');
      setIsAlert(true);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  useEffect(() => {
    const target = document.getElementById(idStringToScrollTo);
    target?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [idStringToScrollTo]);

  /**
   * Open the respective location list based on the selected option.
   * Set the idString so that the parent component can find the element and scroll to that element.
   * Close the dropdown list when a selection has been made.
   * @param event - Mouse event
   * @param selectedOption - Selected companyLocation option to scroll to the selected location cards
   */
  const openCardBasedOnSelectedOption = (
    event: React.MouseEvent<HTMLLIElement>,
    selectedOption: CompanyLocationOption
  ) => {
    const { companyId, restaurantId, locationId } = selectedOption;

    const newMapIsOpenedsByCompanyId = new Map(
      companyContext.mapsIsOpenedById.mapIsOpenedsByCompanyId
    );
    newMapIsOpenedsByCompanyId.set(companyId, {
      isOpened: true,
      isRestaurantListOpened: true,
    });
    const newMapIsOpenedAndParentIdByRestaurantId = new Map(
      companyContext.mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId
    );
    const isOpenedAndParentId =
      companyContext.mapsIsOpenedById.mapIsOpenedAndParentIdByRestaurantId.get(restaurantId)!;
    newMapIsOpenedAndParentIdByRestaurantId.set(restaurantId, {
      ...isOpenedAndParentId,
      isOpened: true,
    });

    companyContext.updateMapsIsOpenedById({
      mapIsOpenedsByCompanyId: newMapIsOpenedsByCompanyId,
      mapIsOpenedAndParentIdByRestaurantId: newMapIsOpenedAndParentIdByRestaurantId,
    });
    setIdStringToScrollTo(`location_${locationId}`);
  };

  return (
    <div className="GroupManagementPage p-5 pt-3 mb-10">
      {isAlert && (
        <Alert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      <div className="flex flex-wrap justify-between content-center gap-y-3">
        <h1 className="font-bold text-[#152445] text-3xl inline-block align-text-bottom">
          Groups (<span>{companyContext.arrCompanyService.length} Total</span>)
        </h1>
        <div className="grow flex flex-wrap items-end justify-center gap-x-3 gap-y-3 md:justify-end">
          <SearchBar
            arrCompanyService={companyContext.arrCompanyService}
            updateFunction={openCardBasedOnSelectedOption}
          />
          <AddCompanyButton />
        </div>
      </div>
      {inputCardsContext.input.inputModeIndex === COMPANY_SERVICE_INPUT_MODE.addCompanyService && (
        <CompanyServiceInputCardsContainer />
      )}
      <CompanyList />
    </div>
  );
};

export default CompanyManagementPage;
