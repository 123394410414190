import axios from 'axios';
import { ExportToCsv } from 'export-to-csv';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, Card, CardBody, Checkbox, Typography } from '@material-tailwind/react';

import downloadBaselineReviewPowerpoint from './BaselineReviewDownload';
import LoadingSpinner from './LoadingSpinner';
import downloadPropertyMonthlyReviewPowerpoint from './PropertyMonthlyReviewDownload';
import SearchBar from '../Common/SearchBar';
import AppAlert from '../CompanyComponents/alerts/Alert';
import CompanyContext from '../../context/CompanyContext';
import LumiticsLogo from '../../images/Lumitics-logo-green-0.png';
import {
  CompanyLocationOption,
  CompanyService,
  LocationMonthlyReportData,
  LocationService,
  RestaurantService,
  Service,
} from '../../interfaces';
import EditMonthlyReviewSlideDialog from './EditMonthlyReviewSlideDialog';
import {
  getDefaultPropertyMonthlyReviewSlideMetadata,
  sortByNamePriority,
} from './EditMonthlyReviewSlide/helper';

const CALCULATOR_CONNECTION_ERROR = 'CalculatorConnectionError';

/**
 * This function retrieves the date of the previous month's first and last dates to be used as default values of datepicker
 * @returns Object - Object containing first date and last date of previous month
 * @returns Object.firstDateOfPrevMonth - first date of previous month
 * @returns Object.lastDateOfPrevMonth - last date of previous month
 */
const getFirstAndLastDateOfPrevMonth = () => {
  const currentDate = new Date();
  const firstDateOfPrevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const lastDateOfPrevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  return {
    firstDateOfPrevMonth: new DateObject(firstDateOfPrevMonth),
    lastDateOfPrevMonth: new DateObject(lastDateOfPrevMonth),
  };
};

/**
 * This function retrieves all valid company services
 * @property arrCompanyService - Array of all companyService
 * @returns arrValidCompanyService - Array of all valid companyService, where only valid services are included. Note that array of valid service can be empty.
 */
const getArrValidCompanyService = (arrCompanyService: Array<CompanyService>) => {
  const arrValidCompanyService: Array<CompanyService> = [];
  arrCompanyService.forEach((companyService) => {
    const { arrRestaurantService, ...company } = companyService;
    const validCompanyService: CompanyService = {
      ...company,
      arrRestaurantService: [],
    };
    arrRestaurantService.forEach((restaurantService) => {
      const { arrLocationService, ...restaurant } = restaurantService;
      const validRestaurantService: RestaurantService = {
        ...restaurant,
        arrLocationService: [],
      };
      arrLocationService.forEach((locationService) => {
        const { arrService, ...location } = locationService;
        const validLocationService = {
          ...location,
          arrService: arrService.filter(
            (service) =>
              service.isValid && service.startDate && service.serviceType.type !== 'Placeholder'
          ),
        };
        if (validLocationService.arrService.length !== 0)
          validRestaurantService.arrLocationService.push(validLocationService);
      });
      if (validRestaurantService.arrLocationService.length !== 0)
        validCompanyService.arrRestaurantService.push(validRestaurantService);
    });
    if (validCompanyService.arrRestaurantService.length !== 0)
      arrValidCompanyService.push(validCompanyService);
  });
  return arrValidCompanyService;
};

const ERROR_MESSAGE = {
  noDataInDateRange: 'Specified date range has no report data',
  fetchReportError: 'Error fetching report data from database',
  noImageInDateRange: 'Specified date range has no images',
  fetchImageError: 'Error fetching images from database',
  fetchRestaurantError: 'Error fetching property data',
  fetchPropertyMonthlyReviewError: 'Error fetching property review report',
  fetchPropertyMonthlyReviewWithCalculatorConnectionError:
    'Connection error. Please try again a few minutes later.',
  fetchBaselineReviewError: 'Error fetching baseline review report',
  issueHasNegativeWeightWasteError:
    'Report has been successfully downloaded but there is at least 1 negative weight waste in the issue slides',
  fetchPropertyTopWasteError: 'Error fetching property top waste images',
  dateChosenExceedSevenDaysError:
    'Property top wastes has been successfully downloaded but only for the first 7 days',
};

const ERROR_TYPE = {
  fatal: 'fatal',
  warning: 'warning',
};

const IS_TAGGED_VALUES: { [key: string]: boolean | string | undefined } = {
  all: undefined,
  allExceptUntagged: 'allExceptUntagged',
  taggedAndResolvable: 'taggedAndResolvable',
  taggedAndMapped: true,
  untagged: false,
};

const WEEKDAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const QUICK_SELECT_OPTION = {
  yesterday: 'Yesterday',
  lastWeek: 'Last Week',
  lastMonth: 'Last Month',
  last3Months: 'Last 3 Months',
  fromPropertyEarliestStartDate: 'From Property Earliest Start Date',
};

const ReportInputCard = () => {
  const companyContext = useContext(CompanyContext);
  const propertyTopWasteRef = useRef<any>();
  const { firstDateOfPrevMonth, lastDateOfPrevMonth } = getFirstAndLastDateOfPrevMonth();
  const { user } = useAuth0();

  const initialFormState: {
    companyField: string;
    restaurantField: string;
    mapSetSelectedServiceIdBySelectedLocationId: Map<number, Set<number>>;
    dateRange: Array<DateObject>;
    isTagged: boolean | string | undefined;
    includeIssueSlides: boolean;
  } = {
    companyField: 'Select Group',
    restaurantField: 'Select Property',
    mapSetSelectedServiceIdBySelectedLocationId: new Map(),
    dateRange: [firstDateOfPrevMonth, lastDateOfPrevMonth],
    isTagged: IS_TAGGED_VALUES.taggedAndResolvable,
    includeIssueSlides: false,
  };

  const initialError: {
    isVisible: boolean;
    message: string;
    errorType: string;
  } = {
    isVisible: false,
    message: '',
    errorType: '',
  };

  const [alertHeader, setAlertHeader] = useState('');
  const [arrAlertMessage, setArrAlertMessage] = useState<Array<any>>([]);
  const [arrValidCompanyService, setArrValidCompanyService] = useState<Array<CompanyService>>([]);
  const [error, setError] = useState(initialError);
  const [formState, setFormState] = useState(initialFormState);
  const [isAlert, setIsAlert] = useState(false);
  const [isCompanyReportLoading, setIsCompanyReportLoading] = useState<boolean>(false);
  const [isRestaurantReportLoading, setIsRestaurantReportLoading] = useState<boolean>(false);
  const [isLocationReportLoading, setIsLocationReportLoading] = useState<boolean>(false);
  const [isCompanyDailyWasteReportLoading, setIsCompanyDailyWasteReportLoading] =
    useState<boolean>(false);
  const [isRestaurantDailyWasteReportLoading, setIsRestaurantDailyWasteReportLoading] =
    useState<boolean>(false);
  const [isLocationDailyWasteReportLoading, setIsLocationDailyWasteReportLoading] =
    useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<undefined | DateObject>(undefined);
  const [isPropertyMonthlyReviewLoading, setIsPropertyMonthlyReviewLoading] =
    useState<boolean>(false);
  const [isPropertyTopWasteLoading, setIsPropertyTopWasteLoading] = useState<boolean>(false);
  const [isBaselineReviewLoading, setIsBaselineReviewLoading] = useState<boolean>(false);
  const [minStartDate, setMinStartDate] = useState<undefined | DateObject>(undefined);
  const [dataToDisplay, setDataToDisplay] = useState<any>(null);
  const [setLocationIdExpanded, setSetLocationIdExpanded] = useState<Set<number>>(new Set());
  const [isEditMonthlyReviewSlideDialogOpened, setIsEditMonthlyReviewSlideDialogOpened] =
    useState<boolean>(false);
  const [
    currentRestaurantIdEditingMonthlyReviewSlideMetadata,
    setCurrentRestaurantIdEditingMonthlyReviewSlideMetadata,
  ] = useState<number | null>(null);

  useEffect(() => {
    setArrValidCompanyService(getArrValidCompanyService(companyContext.arrCompanyService));
  }, [companyContext.arrCompanyService]);

  /**
   * This function takes in display message to be displayed and sets the error state to the message
   * @param message String - Message to be displayed
   */
  const displayError = (message: string, errorType: string) => {
    setError({
      isVisible: true,
      message,
      errorType,
    });
  };

  /**
   * This function resets the error message back to its initial value
   */
  const resetErrorMessage = () => setError(initialError);

  /**
   * This function sets the isCompanyReportLoading / isRestaurantReportLoading / isLocationReportLoading states to true or false
   * depending on whichever report the user is downloading
   * @param findBy - 'company', 'restaurant', 'location'
   * @param isTrue - boolean to set the states to true or false
   */
  const setIsReportLoading = (findBy: string, isTrue: boolean) => {
    if (findBy === 'company') {
      setIsCompanyReportLoading(isTrue);
    } else if (findBy === 'restaurant') {
      setIsRestaurantReportLoading(isTrue);
    } else if (findBy === 'location') {
      setIsLocationReportLoading(isTrue);
    }
  };

  /**
   * This function handles the generation of csv file for download
   */
  const generateReport = async (findBy: string) => {
    try {
      setIsReportLoading(findBy, true);
      const {
        companyField,
        restaurantField,
        mapSetSelectedServiceIdBySelectedLocationId,
        dateRange,
      } = formState;
      const [fromDate, toDate] = dateRange;
      let id;
      let fileName;
      const companyFieldParsed = JSON.parse(companyField);
      const { isAirline, name: companyName, companyId, index: companyIndex } = companyFieldParsed;
      if (findBy === 'company') {
        id = companyId;
        fileName = `Group_${companyName}_${fromDate}_${toDate}`;
      } else if (findBy === 'restaurant') {
        const { restaurantId, name: restaurantName } = JSON.parse(restaurantField);
        id = restaurantId;
        fileName = `Property_${companyName}_${restaurantName}_${fromDate}_${toDate}`;
      } else if (findBy === 'location') {
        const companyService = arrValidCompanyService[companyIndex];
        const { name: restaurantName, index: restaurantIndex } = JSON.parse(restaurantField);
        // Only 1 location is selected to enable the location generateReport button
        const [[locationId]] = Array.from(mapSetSelectedServiceIdBySelectedLocationId.entries());
        const restaurantService = companyService.arrRestaurantService[restaurantIndex];
        const locationService = restaurantService.arrLocationService.find(
          (locationService) => locationService.locationId === locationId
        )!;
        id = locationId;
        fileName = `Location_${companyName}_${restaurantName}_${locationService.name}_${fromDate}_${toDate}`;
      }
      const startDate = fromDate.format('YYYY-MM-DD');
      const endDate = toDate.format('YYYY-MM-DD');
      const response = await axios.post('/report-management/fetch-report', {
        isAirline,
        findBy,
        attribute: {
          id,
          startDate,
          endDate,
        },
      });

      const { arrReport } = response.data;

      // If returned arrReport has length 0, display 'no data' error
      if (arrReport.length === 0) {
        displayError(ERROR_MESSAGE.noDataInDateRange, ERROR_TYPE.fatal);
        setIsReportLoading(findBy, false);
        return;
      }

      const options = {
        filename: fileName,
        quoteStrings: '',
        showLabels: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(arrReport);
      setIsReportLoading(findBy, false);
    } catch (error) {
      setIsReportLoading(findBy, false);
      displayError(ERROR_MESSAGE.fetchReportError, ERROR_TYPE.fatal);
    }
  };

  /**
   * This function sets the isCompanyDailyWasteReportLoading / isRestaurantDailyWasteReportLoading / isLocationDailyWasteReportLoading states to true or false
   * depending on whichever report the user is downloading
   * @param findBy - 'company', 'restaurant', 'location'
   * @param isTrue - boolean to set the states to true or false
   */
  const setIsDailyWasteReportLoading = (findBy: string, isTrue: boolean) => {
    if (findBy === 'company') {
      setIsCompanyDailyWasteReportLoading(isTrue);
    } else if (findBy === 'restaurant') {
      setIsRestaurantDailyWasteReportLoading(isTrue);
    } else if (findBy === 'location') {
      setIsLocationDailyWasteReportLoading(isTrue);
    }
  };

  /**
   * This function handles the generation of aggregated daily service waste data csv file for download
   */
  const generateDailyWasteReport = async (findBy: string) => {
    try {
      const {
        companyField,
        restaurantField,
        mapSetSelectedServiceIdBySelectedLocationId,
        dateRange,
      } = formState;
      const [fromDate, toDate] = dateRange;
      let id;
      let fileName;
      const companyFieldParsed = JSON.parse(companyField);
      const { isAirline, name: companyName, companyId, index: companyIndex } = companyFieldParsed;
      if (isAirline) {
        setIsAlert(true);
        setAlertHeader('Invalid Group Selected');
        setArrAlertMessage([
          `Only non-airline group can be selected for Daily Waste CSV file download`,
        ]);
        return;
      }
      setIsDailyWasteReportLoading(findBy, true);

      if (findBy === 'company') {
        id = companyId;
        fileName = `Group_${companyName}_${fromDate}_${toDate}_daily_waste_data`;
      } else if (findBy === 'restaurant') {
        const { restaurantId, name: restaurantName } = JSON.parse(restaurantField);
        id = restaurantId;
        fileName = `Property_${companyName}_${restaurantName}_${fromDate}_${toDate}_daily_waste_data`;
      } else if (findBy === 'location') {
        const companyService = arrValidCompanyService[companyIndex];
        const { name: restaurantName, index: restaurantIndex } = JSON.parse(restaurantField);
        // Only 1 location is selected to enable the location generateDailyWasteReport button
        const [[locationId]] = Array.from(mapSetSelectedServiceIdBySelectedLocationId.entries());
        const restaurantService = companyService.arrRestaurantService[restaurantIndex];
        const locationService = restaurantService.arrLocationService.find(
          (locationService) => locationService.locationId === locationId
        )!;
        id = locationId;
        fileName = `Location_${companyName}_${restaurantName}_${locationService.name}_${fromDate}_${toDate}_daily_waste_data`;
      }
      const startDate = fromDate.format('YYYY-MM-DD');
      const endDate = toDate.format('YYYY-MM-DD');
      const response = await axios.post('/report-management/fetch-daily-waste-report', {
        findBy,
        attribute: {
          id,
          startDate,
          endDate,
        },
      });

      const { arrReport } = response.data;

      // If returned arrReport has length 0, display 'no data' error
      if (arrReport.length === 0) {
        displayError(ERROR_MESSAGE.noDataInDateRange, ERROR_TYPE.fatal);
        setIsDailyWasteReportLoading(findBy, false);
        return;
      }
      const options = {
        filename: fileName,
        quoteStrings: '',
        showLabels: true,
        useKeysAsHeaders: true,
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(arrReport);
      setIsDailyWasteReportLoading(findBy, false);
    } catch (error) {
      setIsDailyWasteReportLoading(findBy, false);
      displayError(ERROR_MESSAGE.fetchReportError, ERROR_TYPE.fatal);
    }
  };

  /**
   * This function reads the referenced component from the DOM and converts the html to a canvas to be
   * downloaded as a jpeg by the user
   * @param fileName - Name of file to be downloaded
   */
  const initiatePropertyTopWasteImageDownload = async (fileName: string) => {
    const elementToBeDownloaded = propertyTopWasteRef.current;
    const canvas = await html2canvas(elementToBeDownloaded!, { useCORS: true, scale: 2 });
    const data = canvas.toDataURL('image/jpeg');
    const link = document.createElement('a');

    link.href = data;
    link.download = `${fileName}.jpg`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /**
   * This function handles the fetching and generation of property top wastes to be downloaded
   *
   */
  const downloadPropertyTopWaste = async () => {
    try {
      setIsPropertyTopWasteLoading(true);
      const { dateRange, restaurantField, companyField } = formState;
      const [fromDate, toDate] = dateRange;
      const startDate = fromDate.format('YYYY-MM-DD');
      const endDate = toDate.format('YYYY-MM-DD');
      const endDateAfter7Days = DateTime.fromISO(startDate)
        .plus({ days: 6 })
        .toFormat('yyyy-MM-dd');
      const companyFieldParsed = JSON.parse(companyField);
      const restaurantFieldParsed = JSON.parse(restaurantField);
      const companyService = arrValidCompanyService[Number(companyFieldParsed.index)];
      const restaurantService =
        companyService.arrRestaurantService[Number(restaurantFieldParsed.index)];
      const request = {
        restaurantService,
        startDate,
        endDate: endDate > endDateAfter7Days ? endDateAfter7Days : endDate,
      };
      const response = await axios.post('/report-management/fetch-property-top-waste', request);
      const { arrLocationServiceTopWasteDetail } = response.data;
      // For loop used due to requirement to await DOM changes and downloading of each location's top waste images
      for (
        let locationIndex = 0;
        locationIndex < arrLocationServiceTopWasteDetail.length;
        locationIndex += 1
      ) {
        const { locationName, arrTopWasteDetailByDateByService } =
          arrLocationServiceTopWasteDetail[locationIndex];
        for (
          let serviceIndex = 0;
          serviceIndex < arrTopWasteDetailByDateByService.length;
          serviceIndex += 1
        ) {
          const { date, arrTopWasteDetailByService } =
            arrTopWasteDetailByDateByService[serviceIndex];
          setDataToDisplay({
            locationName,
            date,
            arrTopWasteDetailByService,
          });
          // setTimeout required for setState above to take effect on the DOM before initiating image download
          // This is required due to html2canvas implementation that REQUIRES rendering on the dom before a "screenshot"
          // is captured to be downloaded to the user
          await new Promise((resolve) => setTimeout(resolve, 500));
          await initiatePropertyTopWasteImageDownload(
            `${locationName}_${arrLocationServiceTopWasteDetail[locationIndex].arrTopWasteDetailByDateByService[serviceIndex].date}`
          );
        }
      }
      if (endDate > endDateAfter7Days)
        displayError(ERROR_MESSAGE.dateChosenExceedSevenDaysError, ERROR_TYPE.warning);
    } catch (error) {
      console.log(error);
      displayError(ERROR_MESSAGE.fetchPropertyTopWasteError, ERROR_TYPE.fatal);
    } finally {
      setDataToDisplay(null);
      setIsPropertyTopWasteLoading(false);
    }
  };

  /**
   * This function handles the images' signed url returned for download
   * Each signed url returns an image in 'blob' format which is accepted by the JSZip API
   * The zip file is then generated and downloaded by the user via saveAs
   */
  const downloadImage = async () => {
    const { mapSetSelectedServiceIdBySelectedLocationId, isTagged, dateRange } = formState;
    const [fromDate, toDate] = dateRange;
    const startDate = fromDate.format('YYYY-MM-DD');
    const endDate = toDate.format('YYYY-MM-DD');
    // Only 1 location is selected to enable the downloadImage button
    const [[locationId, setSelectedServiceId]] = Array.from(
      mapSetSelectedServiceIdBySelectedLocationId.entries()
    );
    const arrServiceId = Array.from(setSelectedServiceId);
    const request = {
      arrServiceId,
      startDate,
      endDate,
      isTagged,
    };
    try {
      setIsImageLoading(true);
      const response = await axios.post('/report-management/fetch-image-signed-url', request);

      const { arrImageSignedUrl, arrDownloadError } = response.data;
      const hasImageSignedUrl = arrImageSignedUrl.length > 0;
      const hasDownloadError = arrDownloadError.length > 0;

      // If returned hasImageSignedUrl and arrDownloadError both has length 0, display 'no image' error
      if (!hasImageSignedUrl && !hasDownloadError) {
        displayError(ERROR_MESSAGE.noImageInDateRange, ERROR_TYPE.fatal);
        return;
      }

      // If returned hasImageSignedUrl has images to be downloaded, package them into a zip file and ship to user
      if (hasImageSignedUrl) {
        const { companyField, restaurantField } = formState;
        const companyFieldParsed = JSON.parse(companyField);
        const restaurantFieldParsed = JSON.parse(restaurantField);
        const companyService = arrValidCompanyService[Number(companyFieldParsed.index)];
        const restaurantService =
          companyService.arrRestaurantService[Number(restaurantFieldParsed.index)];
        const locationService = restaurantService.arrLocationService.find(
          (locationService) => locationService.locationId === locationId
        )!;
        const zip = new JSZip();
        await Promise.all(
          arrImageSignedUrl.map(async (imageSignedUrl: { name: string; signedUrl: string }) => {
            const imageResponse = await axios.get(imageSignedUrl.signedUrl, {
              responseType: 'blob',
            });
            const image = imageResponse.data;
            zip.file(imageSignedUrl.name, image);
          })
        );
        const zipFile = await zip.generateAsync({ type: 'blob' });
        const fileName = `${JSON.parse(companyField).name}_${JSON.parse(restaurantField).name}_${
          locationService.name
        }_${locationService.arrService.map((service) => service.name).join('_')}_${
          isTagged === undefined ? 'All' : isTagged === false ? 'Untagged' : 'Tagged'
        }_${fromDate}_${toDate}`;
        saveAs(zipFile, fileName);
      }

      // If returned arrDownloadError has images unable to be downloaded, notify user to notify software team
      // Count of download errors are displayed out of total images
      // If there are a mix of valid images and download errors, valid images are downloaded and users are notified of its count
      if (hasDownloadError) {
        setIsAlert(true);
        setAlertHeader('Invalid Download(s) Exist(s)');
        setArrAlertMessage([
          `Of the service's ${
            arrImageSignedUrl.length + arrDownloadError.length
          } image downloads, ${arrDownloadError.length} had returned errors.`,
          `${
            hasImageSignedUrl
              ? `Remaining ${arrImageSignedUrl.length} images have been downloaded.`
              : ''
          }`,
          'Please screenshot this alert, and notify software team immediately.',
          `Datetime: ${new Date().toLocaleString()}`,
        ]);
      }
    } catch (error) {
      displayError(ERROR_MESSAGE.fetchImageError, ERROR_TYPE.fatal);
    } finally {
      setIsImageLoading(false);
    }
  };

  /**
   * This function handles the backend request for '/report-management/fetch-graph'
   * Waste images are returned as signed url due to Google App Engine's max 32mb response limit, hence image fetching
   * has to be done in the frontend. Graph images are returned as base64 strings as they are constructed in the backend
   * and are relatively much lower in size (~50kb per graph image).
   * @returns Object.arrLocationData - Array of location Waste analysis for the property
   * @returns Object.fileName - Name of file to be downloaded
   * @returns Object.restaurantService - Restaurant and locationService of graphs fetched for
   */
  const fetchGraph = async () => {
    const {
      dateRange,
      companyField,
      restaurantField,
      mapSetSelectedServiceIdBySelectedLocationId,
      isTagged,
    } = formState;
    const [fromDate, toDate] = dateRange;
    const startDate = fromDate.format('YYYY-MM-DD');
    const endDate = toDate.format('YYYY-MM-DD');
    const companyFieldParsed = JSON.parse(companyField);
    const restaurantFieldParsed = JSON.parse(restaurantField);
    const companyService = arrValidCompanyService[Number(companyFieldParsed.index)];
    const restaurantService =
      companyService.arrRestaurantService[Number(restaurantFieldParsed.index)];
    const arrLocationServiceToFetchGraph: LocationService[] = [];
    restaurantService.arrLocationService.forEach((locationService) => {
      const { locationId, arrService } = locationService;
      if (mapSetSelectedServiceIdBySelectedLocationId.has(locationId)) {
        const setSelectedServiceId = mapSetSelectedServiceIdBySelectedLocationId.get(locationId);
        const arrServiceSelected = arrService.filter((service) =>
          setSelectedServiceId!.has(service.serviceId)
        );
        sortByNamePriority(arrServiceSelected);
        arrLocationServiceToFetchGraph.push({
          ...locationService,
          arrService: arrServiceSelected,
        });
      }
    });
    const restaurantServiceToFetchGraph = {
      ...restaurantService,
      arrLocationService: arrLocationServiceToFetchGraph,
    };
    const request = {
      companyName: companyFieldParsed.name,
      restaurantService: restaurantServiceToFetchGraph,
      startDate,
      endDate,
      isTagged,
    };
    const response = await axios.post('/report-management/fetch-graph', request);
    return {
      ...response.data,
      fileName: `${restaurantFieldParsed.name}_${fromDate}_${toDate}`,
      restaurantService: restaurantServiceToFetchGraph,
    };
  };

  /**
   * This function handles the backend request for '/report-management/fetch-baseline-graph'
   * Waste images are returned as signed url due to Google App Engine's max 32mb response limit, hence image fetching
   * has to be done in the frontend. Graph images are returned as base64 strings as they are constructed in the backend
   * and are relatively much lower in size (~50kb per graph image).
   * @returns Object.baselineReportData - Object containing key: locationName, value: locationData
   * @returns Object.fileName - Name of file to be downloaded
   * @returns Object.restaurantService - Restaurant, location and arrService of graphs fetched for
   */
  const fetchBaselineGraph = async () => {
    const {
      dateRange,
      companyField,
      restaurantField,
      mapSetSelectedServiceIdBySelectedLocationId,
      isTagged,
    } = formState;
    const [fromDate, toDate] = dateRange;
    const startDate = fromDate.format('YYYY-MM-DD');
    const endDate = toDate.format('YYYY-MM-DD');
    const companyFieldParsed = JSON.parse(companyField);
    const restaurantFieldParsed = JSON.parse(restaurantField);
    // Only 1 location is selected to enable the baseline report button
    const [[locationId, setSelectedServiceId]] = Array.from(
      mapSetSelectedServiceIdBySelectedLocationId.entries()
    );
    const companyService = arrValidCompanyService[Number(companyFieldParsed.index)];
    const restaurantService =
      companyService.arrRestaurantService[Number(restaurantFieldParsed.index)];
    const locationService = restaurantService.arrLocationService.find(
      (locationService) => locationService.locationId === locationId
    )!;
    const restaurantServiceToFetchBaselineGraph = {
      ...restaurantService,
      arrLocationService: [
        {
          ...locationService,
          arrService: locationService!.arrService.filter((service) =>
            setSelectedServiceId.has(service.serviceId)
          ),
        },
      ],
    };
    const request = {
      companyName: companyFieldParsed.name,
      restaurantService: restaurantServiceToFetchBaselineGraph,
      startDate,
      endDate,
      isTagged,
    };
    const response = await axios.post('/report-management/fetch-baseline-graph', request);
    return {
      ...response.data,
      fileName: `Baseline_Report_For_${restaurantFieldParsed.name}_${locationService.name}_${fromDate}_${toDate}`,
      restaurantService: restaurantServiceToFetchBaselineGraph,
    };
  };

  /**
   * This function is invoked on change of Company select element to update formState and the restaurantService
   * selection(s)
   * @param event - Change event provided by the select element, provides companyId, companyName, isAirline
   */
  const updateCompanyFormState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormState({
      ...formState,
      companyField: event.target.value,
      restaurantField: 'Select Property',
      mapSetSelectedServiceIdBySelectedLocationId: new Map(),
    });
    setMinDate(undefined);
    resetErrorMessage();
  };

  /**
   * This function is invoked on change date range picker to update formState
   * @param dateRange - Array containing from and to DateObjects
   */
  const updateDateRangeFormState = (dateRange: Array<DateObject>) => {
    setFormState({ ...formState, dateRange });
    resetErrorMessage();
  };

  /**
   * This function is invoked on change of Restaurant select element to update formState
   * @param event - Change event providing restaurantName, restaurant's index
   */
  const updateRestaurantFormState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const updatedFormState = {
      ...formState,
      restaurantField: event.target.value,
      mapSetSelectedServiceIdBySelectedLocationId: new Map(),
    };
    const companyFieldParsed = JSON.parse(updatedFormState.companyField);
    const restaurantFieldParsed = JSON.parse(updatedFormState.restaurantField);
    const companyService = arrValidCompanyService[Number(companyFieldParsed.index)];
    const restaurantService =
      companyService.arrRestaurantService[Number(restaurantFieldParsed.index)];
    const { propertyEarliestStartDate, propertyEarliestProjectStartDate } =
      getPropertyEarliestStartDateAndProjectStartDate(restaurantService);
    if (propertyEarliestProjectStartDate !== null) {
      const propertyEarliestProjectStartDateInDateObjectFormat = new DateObject(
        propertyEarliestProjectStartDate
      );
      setMinDate(propertyEarliestProjectStartDateInDateObjectFormat);
      // If min date is later than currently selected from date, then set from date as min date
      if (propertyEarliestProjectStartDate > formState.dateRange[0].format('YYYY-MM-DD')) {
        updatedFormState.dateRange = [
          propertyEarliestProjectStartDateInDateObjectFormat,
          formState.dateRange[1],
        ];
      }
      // If min date is later than currently selected to date, then set to date as min date
      if (propertyEarliestProjectStartDate > formState.dateRange[1].format('YYYY-MM-DD')) {
        updatedFormState.dateRange[1] = propertyEarliestProjectStartDateInDateObjectFormat;
      }
    } else {
      setMinDate(undefined);
    }
    setMinStartDate(
      propertyEarliestStartDate === null ? undefined : new DateObject(propertyEarliestStartDate)
    );
    setFormState(updatedFormState);
    resetErrorMessage();
  };

  /**
   * This function retrieves the selected property's earliest project and baseline start date among its valid services
   * @param restaurantService - Restaurant service to get the earliest project and baseline start date of the property
   * @returns propertyEarliestStartDate - Property's earliest baseline start date among its valid services
   * @returns propertyEarliestProjectStartDate - Property's earliest project start date among its valid services
   */
  const getPropertyEarliestStartDateAndProjectStartDate = (
    restaurantService: RestaurantService
  ): {
    propertyEarliestStartDate: string | null;
    propertyEarliestProjectStartDate: string | null;
  } => {
    let propertyEarliestStartDate: string | null = null;
    let propertyEarliestProjectStartDate: string | null = null;
    restaurantService.arrLocationService.forEach((locationService) => {
      locationService.arrService.forEach((service) => {
        const { projectStartDate, isValid, startDate, serviceType } = service;
        if (isValid && startDate !== null && serviceType.type !== 'Placeholder') {
          if (propertyEarliestProjectStartDate === null) {
            propertyEarliestProjectStartDate = projectStartDate;
          } else if (projectStartDate < propertyEarliestProjectStartDate) {
            propertyEarliestProjectStartDate = projectStartDate;
          }

          if (propertyEarliestStartDate === null) {
            propertyEarliestStartDate = startDate;
          } else if (startDate < propertyEarliestStartDate) {
            propertyEarliestStartDate = startDate;
          }
        }
      });
    });
    return { propertyEarliestStartDate, propertyEarliestProjectStartDate };
  };

  /**
   * This function is invoked on clicking a selection of company, restaurant and location on the SearchBar component.
   * With the selection of a location, the date picker will also implement a min date selectable by the user according to
   * the property's earliest project start date.
   * If the currently selected date range falls before partially or fully of the property's earliest project start date,
   * then the selected 'from' date will be updated to the property's earliest project start date.
   * @param event - Mouse event
   * @param selectedOption - Selected companyLocation option providing companyField, restaurantField and mapSetSelectedServiceIdByLocationId information
   */
  const updateCompanyLocationFormState = (
    event: React.MouseEvent<HTMLLIElement>,
    selectedOption: CompanyLocationOption
  ) => {
    const {
      companyId,
      companyName,
      isAirline,
      companyIndex,
      restaurantId,
      restaurantName,
      restaurantIndex,
      locationId,
      locationIndex,
    } = selectedOption;
    const companyService = arrValidCompanyService[companyIndex];

    const selectedCompanyField = `{"companyId": ${companyId}, "name": "${companyName}", "isAirline": ${isAirline}, "index": ${companyIndex}}`;
    const selectedRestaurantField = `{"name": "${restaurantName}", "restaurantId": ${restaurantId}, "index": ${restaurantIndex}}`;

    // Retrieve arrService in order to get property's earliest project start date
    const restaurantService = companyService.arrRestaurantService[restaurantIndex];
    const { arrService } = restaurantService.arrLocationService[locationIndex];
    const { propertyEarliestStartDate, propertyEarliestProjectStartDate } =
      getPropertyEarliestStartDateAndProjectStartDate(restaurantService);
    const updatedFormState = {
      ...formState,
      companyField: selectedCompanyField,
      restaurantField: selectedRestaurantField,
      mapSetSelectedServiceIdBySelectedLocationId:
        arrService.length !== 0
          ? new Map([[locationId, new Set(arrService.map((service) => service.serviceId))]])
          : new Map(),
    };

    if (propertyEarliestProjectStartDate !== null) {
      const propertyEarliestProjectStartDateInDateObjectFormat = new DateObject(
        propertyEarliestProjectStartDate
      );
      setMinDate(propertyEarliestProjectStartDateInDateObjectFormat);
      // If min date is later than currently selected from date, then set from date as min date
      if (propertyEarliestProjectStartDate > formState.dateRange[0].format('YYYY-MM-DD')) {
        updatedFormState.dateRange = [
          propertyEarliestProjectStartDateInDateObjectFormat,
          formState.dateRange[1],
        ];
      }
      // If min date is later than currently selected to date, then set to date as min date
      if (propertyEarliestProjectStartDate > formState.dateRange[1].format('YYYY-MM-DD')) {
        updatedFormState.dateRange[1] = propertyEarliestProjectStartDateInDateObjectFormat;
      }
    } else {
      setMinDate(undefined);
    }
    setMinStartDate(
      propertyEarliestStartDate === null ? undefined : new DateObject(propertyEarliestStartDate)
    );
    setFormState(updatedFormState);
    resetErrorMessage();
  };

  /**
   * This function is invoked on change of Location checkbox element to update formState
   * @property isChecked - Indicates if the checkbox is checked or unchecked
   * @property locationService - Location with its array of valid services, where its location checkbox is checked or unchecked
   */
  const updateLocationServiceFormState = (isChecked: boolean, locationService: LocationService) => {
    const { locationId, arrService } = locationService;
    const { mapSetSelectedServiceIdBySelectedLocationId } = formState;
    const updatedMapSetSelectedServiceIdBySelectedLocationId = new Map(
      mapSetSelectedServiceIdBySelectedLocationId
    );
    if (isChecked) {
      updatedMapSetSelectedServiceIdBySelectedLocationId.set(
        locationId,
        new Set(arrService.map((service) => service.serviceId))
      );
    } else {
      updatedMapSetSelectedServiceIdBySelectedLocationId.delete(locationId);
    }
    const updatedFormState = {
      ...formState,
      mapSetSelectedServiceIdBySelectedLocationId:
        updatedMapSetSelectedServiceIdBySelectedLocationId,
    };

    setFormState(updatedFormState);
    resetErrorMessage();
  };

  /**
   * This function is invoked on change of Service checkbox element to update formState
   * @property isChecked - Indicates if the checkbox is checked or unchecked
   * @property service -Service where its service checkbox is checked or unchecked
   */
  const updateServiceFormState = (isChecked: boolean, service: Service) => {
    const { locationId, serviceId } = service;
    const { mapSetSelectedServiceIdBySelectedLocationId } = formState;
    const updatedMapSetSelectedServiceIdBySelectedLocationId = new Map(
      mapSetSelectedServiceIdBySelectedLocationId
    );
    if (isChecked) {
      if (updatedMapSetSelectedServiceIdBySelectedLocationId.has(locationId)) {
        updatedMapSetSelectedServiceIdBySelectedLocationId.get(locationId)!.add(serviceId);
      } else {
        updatedMapSetSelectedServiceIdBySelectedLocationId.set(locationId, new Set([serviceId]));
      }
    } else {
      if (updatedMapSetSelectedServiceIdBySelectedLocationId.get(locationId)!.size === 1) {
        updatedMapSetSelectedServiceIdBySelectedLocationId.delete(locationId);
      } else {
        updatedMapSetSelectedServiceIdBySelectedLocationId.get(locationId)!.delete(serviceId);
      }
    }
    const updatedFormState = {
      ...formState,
      mapSetSelectedServiceIdBySelectedLocationId:
        updatedMapSetSelectedServiceIdBySelectedLocationId,
    };

    setFormState(updatedFormState);
    resetErrorMessage();
  };

  /**
   * This function is invoked on change of isTagged radio element to update formState
   * @param isTagged - undefined (all), 'allExceptUntagged' (allExceptUntagged), 'taggedAndResolvable' (taggedAndResolvable), true (taggedAndMapped) or false (untagged)
   */
  const updateIsTaggedFormState = (isTagged: boolean | string | undefined) => {
    setFormState({ ...formState, isTagged });
  };

  /**
   * This function is invoked on change of includeIssueSlides radio element to update formState
   * @param includeIssueSlides - Indicates if issue waste analysis slides is to be included
   */
  const updateIncludeIssueSlidesFormState = (includeIssueSlides: boolean) => {
    setFormState({ ...formState, includeIssueSlides });
  };

  /**
   * This function is called when "Select property's earliest baseline start date" button is clicked to assist
   * users in selecting the earliest start date of services of property selected to the last day of the
   * previous month.
   * In order for this function to be invoked, a property must have been selected before which populates
   * the minDate state, which is the earliest start date of services of property selected.
   * If all property's services have null startDate (minDate remains as undefined), an alert will appear
   * prompting user to add startDate
   * @returns DateObject[] - Array of DateObject
   * @returns DateObject[0] - Starting date of date range
   * @returns DateObject[1] - Ending date of date range
   */
  const selectPropertyEarliestStartDate = (): Array<DateObject> | undefined => {
    if (minStartDate !== undefined) {
      return [new DateObject(minStartDate), lastDateOfPrevMonth];
    } else {
      setIsAlert(true);
      setAlertHeader('No service start date found!');
      setArrAlertMessage([
        'To use this feature, please ensure start date for services under this property has been added.',
      ]);
    }
  };

  /**
   * This function is called when user clicks on any of the date range quick select buttons:
   * 1. Yesterday
   * 2. Last Week
   * 3. Last Month
   * 4. Last 3 Months
   * 4. From Property's Earliest Start Date
   * @param dateRangeOption - Type of quick select the user clicks
   */
  const quickSelectDateRange = (dateRangeOption: string) => {
    let dateRange: Array<DateObject> | undefined;
    const todayMoment = moment();
    switch (dateRangeOption) {
      case QUICK_SELECT_OPTION.yesterday:
        dateRange = [
          new DateObject(todayMoment.clone().subtract(1, 'day').format('YYYY-MM-DD')),
          new DateObject(todayMoment.clone().subtract(1, 'day').format('YYYY-MM-DD')),
        ];
        break;
      case QUICK_SELECT_OPTION.lastWeek:
        dateRange = [
          new DateObject(
            todayMoment.clone().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.lastMonth:
        dateRange = [firstDateOfPrevMonth, lastDateOfPrevMonth];
        break;
      case QUICK_SELECT_OPTION.last3Months:
        dateRange = [
          new DateObject(
            todayMoment.clone().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')
          ),
          new DateObject(
            todayMoment.clone().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
          ),
        ];
        break;
      case QUICK_SELECT_OPTION.fromPropertyEarliestStartDate:
        dateRange = selectPropertyEarliestStartDate();
        break;
      default:
        return;
    }
    if (dateRange) {
      setFormState({
        ...formState,
        dateRange,
      });
    }
  };

  /**
   * This function resets the formState to its initial values
   */
  const resetForm = () => {
    setFormState(initialFormState);
    resetErrorMessage();
    setIsCompanyReportLoading(false);
    setIsRestaurantReportLoading(false);
    setIsLocationReportLoading(false);
    setIsImageLoading(false);
    setMinDate(undefined);
  };

  /**
   * This function handles the generation of powerpoint slides and initiates download for the client browser
   */
  const downloadPropertyMonthlyReview = async () => {
    try {
      setIsPropertyMonthlyReviewLoading(true);
      const fetchGraphRes = await fetchGraph();
      const companyFieldParsed = JSON.parse(formState.companyField);
      const restaurantFieldParsed = JSON.parse(formState.restaurantField);
      const companyService = arrValidCompanyService[Number(companyFieldParsed.index)];
      const restaurantService =
        companyService.arrRestaurantService[Number(restaurantFieldParsed.index)];
      const propertyMonthlyReviewSlideMetadataResponse = await axios.post(
        '/report-management/fetch-property-monthly-review-slide-metadata',
        {
          restaurantId: restaurantService.restaurantId,
        }
      );
      const {
        defaultPropertyMonthlyReviewSlideMetadata,
        mapLocationNameByLocationId,
        mapServiceNameByServiceId,
      } = getDefaultPropertyMonthlyReviewSlideMetadata(
        arrValidCompanyService,
        restaurantService.restaurantId
      )!;
      let monthlyReviewSlideMetadata;
      if (
        propertyMonthlyReviewSlideMetadataResponse.data &&
        propertyMonthlyReviewSlideMetadataResponse.data.monthlyReviewSlideMetadata !== undefined
      ) {
        if (propertyMonthlyReviewSlideMetadataResponse.data.monthlyReviewSlideMetadata === null) {
          monthlyReviewSlideMetadata = defaultPropertyMonthlyReviewSlideMetadata;
        } else {
          monthlyReviewSlideMetadata =
            propertyMonthlyReviewSlideMetadataResponse.data.monthlyReviewSlideMetadata;
        }
      }
      await downloadPropertyMonthlyReviewPowerpoint(
        fetchGraphRes,
        monthlyReviewSlideMetadata,
        mapLocationNameByLocationId,
        mapServiceNameByServiceId,
        formState,
        user
      );
      if (
        formState.includeIssueSlides &&
        fetchGraphRes.arrLocationData.some(
          (locationData: LocationMonthlyReportData) => locationData.hasNegativeWeight
        )
      ) {
        displayError(ERROR_MESSAGE.issueHasNegativeWeightWasteError, ERROR_TYPE.warning);
      }
    } catch (error: any) {
      if (error?.response?.data.error === CALCULATOR_CONNECTION_ERROR) {
        displayError(
          ERROR_MESSAGE.fetchPropertyMonthlyReviewWithCalculatorConnectionError,
          ERROR_TYPE.fatal
        );
      } else {
        displayError(ERROR_MESSAGE.fetchPropertyMonthlyReviewError, ERROR_TYPE.fatal);
      }
    } finally {
      setIsPropertyMonthlyReviewLoading(false);
    }
  };

  /**
   * This function handles the generation of powerpoint slides for baseline report and initiates download for the client browser
   */
  const downloadBaselineReview = async () => {
    try {
      setIsBaselineReviewLoading(true);
      const fetchBaselineGraphRes = await fetchBaselineGraph();
      await downloadBaselineReviewPowerpoint(fetchBaselineGraphRes, formState, user);
      if (fetchBaselineGraphRes.baselineReportData.hasNegativeWeight) {
        displayError(ERROR_MESSAGE.issueHasNegativeWeightWasteError, ERROR_TYPE.warning);
      }
    } catch (error) {
      displayError(ERROR_MESSAGE.fetchBaselineReviewError, ERROR_TYPE.fatal);
    } finally {
      setIsBaselineReviewLoading(false);
    }
  };

  /**
   * Remove if the locationId is already in the set and add if the locationId is not yet in the set. This is to update
   * the state setLocationIdExpanded which holds the location(s) where its service list is to be displayed.
   * @param locationId - locationId to be removed or added to setLocationIdExpanded
   */
  const updateSetLocationIdExpanded = (locationId: number) => {
    const updatedSetLocationIdExpanded = new Set(Array.from(setLocationIdExpanded));
    if (updatedSetLocationIdExpanded.has(locationId)) {
      updatedSetLocationIdExpanded.delete(locationId);
    } else {
      updatedSetLocationIdExpanded.add(locationId);
    }
    setSetLocationIdExpanded(updatedSetLocationIdExpanded);
  };

  const renderLocationCheckbox = (arrLocationService: Array<LocationService>) => {
    const { mapSetSelectedServiceIdBySelectedLocationId } = formState;
    return arrLocationService.map((locationService) => {
      const { locationId, name, arrService } = locationService;
      return (
        <div key={`LocationCheckbox ${locationId}`} className="flex flex-col">
          <div
            className="flex items-center shadow-md bg-gray-300 hover:bg-gray-200"
            onClick={() => updateSetLocationIdExpanded(locationId)}
          >
            <Checkbox
              checked={mapSetSelectedServiceIdBySelectedLocationId.has(locationId)}
              id={`LocationCheckbox ${locationId}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                updateLocationServiceFormState(e.target.checked, locationService);
              }}
              icon={<MdCheck className="h-3 w-3" />}
            />
            <p className="text-base font-bold break-words align-middle">{name}</p>
          </div>
          <div
            className={`grid grid-cols-1 gap-2 min-h-[2.8rem] items-center bg-gray-100 rounded-b-md ${
              setLocationIdExpanded.has(locationId) ? 'visible' : 'hidden'
            }`}
          >
            {renderServiceCheckbox(arrService)}
          </div>
        </div>
      );
    });
  };

  const renderServiceCheckbox = (arrService: Array<Service>) => {
    const { mapSetSelectedServiceIdBySelectedLocationId } = formState;
    return arrService.map((service: Service) => (
      <div key={`ServiceCheckbox ${service.serviceId}`} className="flex items-center pl-6">
        <Checkbox
          checked={
            mapSetSelectedServiceIdBySelectedLocationId.has(service.locationId) &&
            mapSetSelectedServiceIdBySelectedLocationId
              .get(service.locationId)!
              .has(service.serviceId)
          }
          id={`ServiceCheckbox ${service.serviceId}`}
          onChange={(e) => {
            updateServiceFormState(e.target.checked, service);
          }}
          icon={<MdCheck className="h-3 w-3" />}
        />
        <p className="text-base font-normal break-words align-middle">{service.name}</p>
      </div>
    ));
  };

  return (
    <div className="p-3 pt-3 flex flex-col">
      {isAlert && (
        <AppAlert
          arrAlertMessage={arrAlertMessage}
          alertHeader={alertHeader}
          handleOpen={() => {
            setIsAlert(!isAlert);
          }}
          isExpanded={isAlert}
        />
      )}
      <EditMonthlyReviewSlideDialog
        isOpened={isEditMonthlyReviewSlideDialogOpened}
        setIsOpened={setIsEditMonthlyReviewSlideDialogOpened}
        restaurantId={currentRestaurantIdEditingMonthlyReviewSlideMetadata}
      />
      <h1 className="font-bold text-[#152445] text-3xl inline-block align-text-bottom">Reports</h1>
      <div className="grow flex flex-wrap self-center justify-end w-full mb-3">
        <SearchBar
          arrCompanyService={arrValidCompanyService}
          updateFunction={updateCompanyLocationFormState}
        />
      </div>
      <div className="grid grid-cols-1 gap-5 w-full border-2 rounded-xl px-5 py-6 mt-1 self-center">
        <div className="flex flex-col lg:flex-row gap-5 lg:gap-0">
          <div className="flex flex-col flex-1 gap-5 px-5">
            <div className="text-xl font-semibold">
              Group:
              <select
                name="groups"
                className="GroupField mt-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black cursor-pointer"
                value={formState.companyField}
                onChange={(event) => {
                  updateCompanyFormState(event);
                }}
              >
                <option disabled defaultChecked>
                  Select Group
                </option>
                {arrValidCompanyService.map((companyService: CompanyService, index: number) => (
                  <option
                    key={index}
                    value={`{"companyId": ${companyService.companyId}, "name": "${companyService.name}", "isAirline": ${companyService.isAirline}, "index": ${index}}`}
                  >
                    {companyService.name}
                    {companyService.isAirline && ' ✈️'}
                  </option>
                ))}
              </select>
            </div>
            <div className="text-xl font-semibold flex flex-col">
              Date Range:
              <DatePicker
                range
                rangeHover
                value={formState.dateRange}
                onChange={updateDateRangeFormState}
                weekDays={WEEKDAYS}
                numberOfMonths={2}
                weekStartDayIndex={1}
                minDate={minDate}
                maxDate={new DateObject()}
                format="DD/MM/YYYY"
                showOtherDays
                portal
                render={
                  <InputIcon className="DateRangePicker mt-2 text-base block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black cursor-pointer" />
                }
              />
            </div>
            <div className="text-xl font-semibold flex">
              <Button
                color="teal"
                className="YesterdayButton flex-1 px-1 rounded-r-none border-r"
                onClick={() => quickSelectDateRange(QUICK_SELECT_OPTION.yesterday)}
              >
                Yesterday
              </Button>
              <Button
                color="teal"
                className="LastWeekButton flex-1 px-1 rounded-none border-r"
                onClick={() => quickSelectDateRange(QUICK_SELECT_OPTION.lastWeek)}
              >
                Last Week
              </Button>
              <Button
                color="teal"
                className="LastMonthButton flex-1 px-1 rounded-none border-x"
                onClick={() => quickSelectDateRange(QUICK_SELECT_OPTION.lastMonth)}
              >
                Last Month
              </Button>
              <Button
                color="teal"
                className="Last3MonthsButton flex-1 px-1 rounded-none border-x"
                onClick={() => quickSelectDateRange(QUICK_SELECT_OPTION.last3Months)}
              >
                Last 3 Months
              </Button>
              <Button
                color="teal"
                className="FromPropertyEarliestStartDateButton min-w-min text-[10px] flex-1 leading-tight px-1 rounded-l-none border-l py-2"
                disabled={formState.restaurantField === 'Select Property'}
                onClick={() =>
                  quickSelectDateRange(QUICK_SELECT_OPTION.fromPropertyEarliestStartDate)
                }
              >
                Property's earliest baseline start date to end of last month
              </Button>
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2 px-5 lg:border-l-2">
            <div
              className={`text-xl font-semibold flex-1 flex items-center gap-2 justify-between ${
                formState.companyField === 'Select Group' && 'opacity-30'
              }`}
            >
              <div className="flex-1">
                Property:
                <select
                  name="properties"
                  className="PropertyField mt-1 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black cursor-pointer disabled:opacity-30 disabled:cursor-default"
                  value={formState.restaurantField}
                  disabled={formState.companyField === 'Select Group'}
                  onChange={updateRestaurantFormState}
                >
                  <option disabled defaultChecked>
                    Select Property
                  </option>
                  {formState.companyField !== 'Select Group' &&
                    arrValidCompanyService[
                      Number(JSON.parse(formState.companyField).index)
                    ].arrRestaurantService.map((restaurant: RestaurantService, index: number) => (
                      <option
                        key={index}
                        value={`{"name": "${restaurant.name}", "restaurantId": ${restaurant.restaurantId}, "index": ${index}}`}
                      >
                        {restaurant.name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                {formState.restaurantField !== 'Select Property' && (
                  <Button
                    className="EditMonthlyReviewSlideButton bg-purple-500 p-3 rounded w-32 h-14"
                    onClick={() => {
                      setIsEditMonthlyReviewSlideDialogOpened(
                        !isEditMonthlyReviewSlideDialogOpened
                      );
                      setCurrentRestaurantIdEditingMonthlyReviewSlideMetadata(
                        formState.restaurantField !== 'Select Property'
                          ? JSON.parse(formState.restaurantField).restaurantId
                          : null
                      );
                    }}
                  >
                    Edit Monthly Review Slides
                  </Button>
                )}
              </div>
            </div>

            <div
              className={`text-xl font-semibold flex-1 ${
                formState.restaurantField === 'Select Property' && 'opacity-30'
              }`}
            >
              Location & service:
              <Typography className="text-xs w-inherit">
                *select only 1 location for LOCATION REPORT, LOCATION DAILY WASTE REPORT, BASELINE
                REVIEW and SERVICE IMAGES
              </Typography>
              <div className="grid grid-cols-3 gap-2 min-h-[2.8rem] items-start pt-2 pb-1">
                {formState.restaurantField !== 'Select Property' ? (
                  renderLocationCheckbox(
                    arrValidCompanyService[Number(JSON.parse(formState.companyField).index)]
                      .arrRestaurantService[Number(JSON.parse(formState.restaurantField).index)]
                      .arrLocationService
                  )
                ) : (
                  <div className="text-base">Select Location & Service</div>
                )}
              </div>
            </div>
            <div className="text-xl font-semibold flex-1">
              Data Type:
              <div className="flex mt-2">
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="data-type-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.taggedAndResolvable}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.taggedAndResolvable)}
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Tagged And Resolvable
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="data-type-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.taggedAndMapped}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.taggedAndMapped)}
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Tagged And Mapped
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="data-type-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.allExceptUntagged}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.allExceptUntagged)}
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    All Except Untagged
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="data-type-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.all}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.all)}
                  />
                  <label
                    htmlFor="inline-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    All
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="data-type-radio-group"
                    checked={formState.isTagged === IS_TAGGED_VALUES.untagged}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIsTaggedFormState(IS_TAGGED_VALUES.untagged)}
                  />
                  <label
                    htmlFor="inline-checked-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Untagged
                  </label>
                </div>
              </div>
            </div>
            <div className="text-xl font-semibold flex-1">
              Include Issue Slides:
              <Typography className="text-xs">
                *only applicable to Property Monthly Review
              </Typography>
              <div className="flex mt-2">
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="include-issue-waste-analysis-radio-group"
                    checked={formState.includeIssueSlides}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIncludeIssueSlidesFormState(true)}
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex items-center mr-4">
                  <input
                    type="radio"
                    value=""
                    name="include-issue-waste-analysis-radio-group"
                    checked={!formState.includeIssueSlides}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                    onChange={() => updateIncludeIssueSlidesFormState(false)}
                  />
                  <label
                    htmlFor="inline-2-radio"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          {!error.isVisible && (
            <div className="flex gap-2 flex-col lg:flex-row">
              <div className="flex-1 flex">
                <Button
                  className="GroupReportButton bg-regal-blue p-3 flex-1 rounded-r-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    isCompanyReportLoading ||
                    formState.dateRange.length !== 2
                  }
                  onClick={() => generateReport('company')}
                >
                  {isCompanyReportLoading ? <LoadingSpinner /> : 'Group Report (.csv)'}
                </Button>
                <Button
                  className="PropertyReportButton bg-regal-blue p-3 flex-1 rounded-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.dateRange.length !== 2 ||
                    isRestaurantReportLoading
                  }
                  onClick={() => generateReport('restaurant')}
                >
                  {isRestaurantReportLoading ? <LoadingSpinner /> : 'Property Report (.csv)'}
                </Button>
                <Button
                  className="LocationReportButton bg-regal-blue p-3 flex-1 rounded-l-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.mapSetSelectedServiceIdBySelectedLocationId.size !== 1 ||
                    formState.dateRange.length !== 2 ||
                    isLocationReportLoading
                  }
                  onClick={() => generateReport('location')}
                >
                  {isLocationReportLoading ? <LoadingSpinner /> : 'Location Report (.csv)'}
                </Button>
              </div>
              <div className="flex-1 flex">
                <Button
                  className="bg-regal-blue p-3 flex-1 rounded-r-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    isCompanyDailyWasteReportLoading ||
                    formState.dateRange.length !== 2
                  }
                  onClick={() => generateDailyWasteReport('company')}
                >
                  {isCompanyDailyWasteReportLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'Company Daily Waste Report (.csv)'
                  )}
                </Button>
                <Button
                  className="bg-regal-blue p-3 flex-1 rounded-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.dateRange.length !== 2 ||
                    isRestaurantDailyWasteReportLoading
                  }
                  onClick={() => generateDailyWasteReport('restaurant')}
                >
                  {isRestaurantDailyWasteReportLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'Restaurant Daily Waste Report (.csv)'
                  )}
                </Button>
                <Button
                  className="bg-regal-blue p-3 flex-1 rounded-l-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.mapSetSelectedServiceIdBySelectedLocationId.size !== 1 ||
                    formState.dateRange.length !== 2 ||
                    isLocationDailyWasteReportLoading
                  }
                  onClick={() => generateDailyWasteReport('location')}
                >
                  {isLocationDailyWasteReportLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'Location Daily Waste Report (.csv)'
                  )}
                </Button>
              </div>
              <div className="flex-1 flex">
                <Button
                  className="PropertyTopWasteButton bg-regal-blue p-3 flex-1 rounded"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.dateRange.length !== 2 ||
                    isPropertyTopWasteLoading
                  }
                  onClick={downloadPropertyTopWaste}
                >
                  {isPropertyTopWasteLoading ? <LoadingSpinner /> : 'Property Top Waste (.jpeg)'}
                </Button>
              </div>
              <div className="flex-1 flex">
                <Button
                  className="BaselineReviewButton bg-regal-blue p-3 flex-1 rounded-r-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.mapSetSelectedServiceIdBySelectedLocationId.size !== 1 ||
                    formState.dateRange.length !== 2 ||
                    formState.isTagged === IS_TAGGED_VALUES.all ||
                    formState.isTagged === IS_TAGGED_VALUES.untagged ||
                    isBaselineReviewLoading
                  }
                  onClick={downloadBaselineReview}
                >
                  {isBaselineReviewLoading ? <LoadingSpinner /> : 'Baseline Review (.pptx)'}
                </Button>
                <Button
                  className="PropertyMonthlyReviewButton bg-regal-blue p-3 flex-1 rounded-l-none"
                  disabled={
                    formState.companyField === 'Select Group' ||
                    formState.restaurantField === 'Select Property' ||
                    formState.mapSetSelectedServiceIdBySelectedLocationId.size === 0 ||
                    formState.dateRange.length !== 2 ||
                    formState.isTagged === IS_TAGGED_VALUES.all ||
                    formState.isTagged === IS_TAGGED_VALUES.untagged ||
                    isPropertyMonthlyReviewLoading
                  }
                  onClick={downloadPropertyMonthlyReview}
                >
                  {isPropertyMonthlyReviewLoading ? (
                    <LoadingSpinner />
                  ) : (
                    'Property Monthly Review (.pptx)'
                  )}
                </Button>
              </div>
              <Button
                className="ServiceImageButton bg-regal-blue p-3 flex-1"
                disabled={
                  formState.companyField === 'Select Group' ||
                  formState.restaurantField === 'Select Property' ||
                  formState.mapSetSelectedServiceIdBySelectedLocationId.size !== 1 ||
                  formState.dateRange.length !== 2 ||
                  isImageLoading
                }
                onClick={downloadImage}
              >
                {isImageLoading ? <LoadingSpinner /> : 'Service Images (.zip)'}
              </Button>
            </div>
          )}
          {error.isVisible && (
            <Alert
              className={error.errorType === ERROR_TYPE.fatal ? 'bg-red-500' : 'bg-purple-500'}
              variant="filled"
              dismissible={{
                onClose: resetErrorMessage,
              }}
            >
              {error.message}
            </Alert>
          )}
          <Button color="amber" className="mt-3" onClick={resetForm}>
            Reset Fields
          </Button>
        </div>
      </div>
      {/* Below div is a hidden element required for the preparation of property top wastes */}
      <div className="relative overflow-hidden">
        <div ref={propertyTopWasteRef} className="flex flex-col gap-2 p-3 absolute overflow-hidden">
          <>
            <div className="flex mb-4 justify-between">
              <div className="flex items-end gap-2">
                <span className="font-bold text-4xl">{dataToDisplay?.locationName}</span>
                <span className="text-xl italic">({dataToDisplay?.date})</span>
              </div>
              <img src={LumiticsLogo} alt="" />
            </div>
            {dataToDisplay?.arrTopWasteDetailByService.map((topWasteDetailByService: any) => {
              const {
                serviceName,
                totalNumberOfWaste,
                totalWeight,
                arrTopWasteImageSignedUrlByWeight,
              } = topWasteDetailByService;
              return (
                <Card
                  className="border-2 min-w-[1500px] bg-white"
                  key={`service_${dataToDisplay?.date}_${serviceName}`}
                >
                  <CardBody className="grid grid-cols-10 gap-4">
                    <div className="flex flex-col justify-center items-center col-span-1">
                      <span className="font-bold text-xl text-center">{serviceName}</span>
                      <span>{totalNumberOfWaste} throws</span>
                      <span>{totalWeight.toFixed(2)} KG</span>
                    </div>
                    <div className="grid gap-2 col-span-9 grid-cols-6 min-h-[9rem]">
                      {arrTopWasteImageSignedUrlByWeight.map((link: any, index: number) => {
                        return (
                          <div key={`service_${dataToDisplay?.date}_${serviceName}_link_${index}`}>
                            <img src={link} alt="" />
                          </div>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          </>
        </div>
      </div>
    </div>
  );
};

export default ReportInputCard;
