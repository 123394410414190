import { forwardRef } from 'react';
import { BAR_CHART_COLORS_FOR_ALL_SERVICES, SlideProps } from './helper';

const Slide = forwardRef<HTMLDivElement, SlideProps>(
  (
    {
      withOpacity,
      onSelect,
      count,
      selected,
      slide,
      isDragging,
      style,
      mapLocationNameByLocationId,
      mapServiceNameByServiceId,
      ...props
    },
    ref
  ) => {
    return (
      <div
        onClick={(event) => {
          if (onSelect) onSelect(event);
        }}
        ref={ref}
        style={style}
        className={`relative flex items-center gap-2 py-1 px-2 select-none text-sm text-black ${
          isDragging ? 'scale-105' : 'scale-100'
        } ${isDragging || selected ? 'border-blue-400 border rounded bg-blue-50' : ''}`}
        {...props}
      >
        <div
          style={{
            ...(slide.content.serviceId === -1
              ? {
                  background:
                    'linear-gradient(90deg, Red, Orange, Yellow, Green, Blue, Indigo,violet)',
                }
              : {
                  backgroundColor:
                    slide.content.serviceId !== null &&
                    BAR_CHART_COLORS_FOR_ALL_SERVICES[
                      mapServiceNameByServiceId.get(slide.content.serviceId)!.toLowerCase()
                    ]
                      ? BAR_CHART_COLORS_FOR_ALL_SERVICES[
                          mapServiceNameByServiceId.get(slide.content.serviceId)!.toLowerCase()
                        ].default
                      : slide.content.groupName !== null &&
                        BAR_CHART_COLORS_FOR_ALL_SERVICES[slide.content.groupName.toLowerCase()]
                      ? BAR_CHART_COLORS_FOR_ALL_SERVICES[slide.content.groupName.toLowerCase()]
                          .default
                      : 'black',
                }),
          }}
          className={`h-3 w-3 rounded-sm`}
        />
        {`${
          slide.content.locationId !== null
            ? `${mapLocationNameByLocationId.get(slide.content.locationId)} `
            : ''
        }${
          slide.content.serviceId !== -1 && slide.content.serviceId !== null
            ? ` > ${mapServiceNameByServiceId.get(slide.content.serviceId)!} - `
            : ''
        }
        ${slide.content.groupName !== null ? ` > ${slide.content.groupName} - ` : ''}${
          slide.content.headerText
        }`}
        {isDragging && count > 1 && (
          <div className="absolute -top-2 -right-2 bg-blue-800 text-white rounded-full w-6 h-6 flex items-center justify-center">
            <p>{count}</p>
          </div>
        )}
      </div>
    );
  }
);

export default Slide;
