import Pptxgen from 'pptxgenjs';
import { DateObject } from 'react-multi-date-picker';
import { User } from '@auth0/auth0-spa-js';

import {
  GRAPH_PERIOD_TYPE,
  GRAPH_WITH_WITHOUT_PRODUCTION,
  GRAPH_WIDTH_TYPE,
  SLIDE_ATTRIBUTE_BY_HEADER_TEXT,
  SLIDE_CONTENT_TYPE,
  SLIDE_STYLE,
  SLIDE_TYPE,
  WEIGHT_COST_TYPE,
} from '../../constant/LocationMonthlyReviewPowerpoint';
import {
  insertGraph,
  insertImage,
  insertIssueImageSlideForAllServices,
  insertServiceTop6MenuItemTable,
  insertStationGraph,
  insertStationImage,
  insertTable,
  initialiseSlide,
} from '../../functions/PowerpointFunctions';
import {
  LocationMonthlyReportData,
  LocationService,
  Service,
  StationGraphData,
} from '../../interfaces';
import { SlideContent } from './EditMonthlyReviewSlide/helper';

const MAX_NUMBER_OF_BARS_GENERAL = 9;
const MAX_NUMBER_OF_BARS_FOR_STATION = 3;
const NUMBER_OF_IMAGES_DISPLAYED_FOR_PLATE_WASTE_SERVICE = 12;
const NUMBER_OF_IMAGES_DISPLAYED_FOR_NON_PLATE_WASTE_SERVICE = 4;
const INVALID_WASTE_PER_COVER_VALUE = 0.0001;

/**
 * Return 1 as the maximum y-axis if the maximum y value is INVALID_WASTE_PER_COVER_VALUE so that for the INVALID_WASTE_PER_COVER_VALUE value, there will be at least
 * a scale of 0 - 1 for the y-axis. Else return undefined to indicate that no specific maximum y-axis needs to be set in the chart options.
 * @param arrWasteValue - Array of waste values to find if there is a maximum y-axis value to be set
 * @returns maxValue - Maximum value of the y-axis. Undefined if the max value is not to be set.
 */
const getMaxYAxis = (arrWasteValue: (number | null)[]): number | undefined => {
  const arrWasteValueWithNull = arrWasteValue.map((wasteValue) => {
    return wasteValue === null ? 0 : wasteValue;
  });
  const maxValue = Math.max(...arrWasteValueWithNull);
  if (maxValue === 0 || maxValue === INVALID_WASTE_PER_COVER_VALUE) {
    return 1;
  } else {
    return undefined;
  }
};

/**
 * This function takes arrData, and pads them with invisible bar columns / line points to allow them to be centralised among the
 * white space within the chart.
 * 1. For charts with baseline shown (i.e. string 'Baseline' as the first element in arrXAxisValue), the baseline
 * will always be the first bar and line shown, followed by the remaining bars of fixed widths centered among the remaining
 * space up to a maximum of x bars shown in total (as per baseline 8-week chart logic).
 * 2. For bar charts without baseline shown, the bars are centered at fixed widths among the entire chart width if there
 * are less than x bars present. If there are more than x bars present (i.e. more than x months' date range chosen), each
 * bar is subsequently narrowed to fit within the available white space.
 * @param arrData - Array of data that is structured in the format required by the pptxgen addChart
 * @param maxNumberOfBars - Maximum number of bars for different padding
 * @returns arrData - Array of data that has accounted for padding
 */
const padLabelsAndValuesToCentraliseChart = (
  arrData: { name: string; labels: string[]; values: any[] }[],
  maxNumberOfBars: number
) => {
  return arrData.map(({ name, labels, values }) => {
    const hasBaseline = labels[0] === 'Baseline';
    const numberOfBars = values.length;
    let barsToAdd = 0;
    if (numberOfBars < maxNumberOfBars) {
      const isEvenNumberOfBars = !Boolean(numberOfBars % 2);
      barsToAdd = maxNumberOfBars - numberOfBars + (isEvenNumberOfBars ? 1 : 0);
    }
    const valuesWithNullBars = hasBaseline
      ? [values[0]].concat(
          Array(barsToAdd / 2).fill(null),
          values.slice(1),
          Array(barsToAdd / 2).fill(null)
        )
      : Array(barsToAdd / 2)
          .fill(null)
          .concat(values, Array(barsToAdd / 2).fill(null));
    const labelsWithEmptyBars = hasBaseline
      ? [labels[0]].concat(
          Array(barsToAdd / 2).fill(''),
          labels.slice(1),
          Array(barsToAdd / 2).fill('')
        )
      : Array(barsToAdd / 2)
          .fill('')
          .concat(labels, Array(barsToAdd / 2).fill(''));
    return {
      name,
      values: valuesWithNullBars,
      labels: labelsWithEmptyBars,
    };
  });
};

/**
 * Retrieve the required data from graphData and structure it in the format required by pptxgen addChart for location/service group
 * bar and/or line graph
 * @param graphData - Graph data to be structured in the format required by pptxgen addChart
 * @param graphDataAttributes - Attributes that determine what values to retrieve from graphData
 * @param currency - Currency
 * @returns graphPlotData
 */
const retrieveBarOrLineGraphPlotData = (
  graphData: LocationMonthlyReportData['graph']['location'],
  graphDataAttributes: {
    periodType: keyof typeof GRAPH_PERIOD_TYPE;
    barType: 'weight' | 'cost' | 'weightPerCover';
    lineType: 'cover' | 'throw' | undefined;
    withWithoutProduction: keyof typeof GRAPH_WITH_WITHOUT_PRODUCTION;
    widthType: keyof typeof GRAPH_WIDTH_TYPE;
    includeBreakdown?: boolean;
    isDisplayBarLegend: boolean;
  },
  currency: string
) => {
  const {
    periodType,
    barType,
    lineType,
    withWithoutProduction,
    widthType,
    includeBreakdown,
    isDisplayBarLegend,
  } = graphDataAttributes;
  const graphDataOfAPeriodSelected = graphData[withWithoutProduction][periodType];
  const { arrService: arrServiceGraphData } = graphData;
  let barGraphData;
  let barGraphDataForSummedValues;
  let barMaxYAxisValue;
  if (includeBreakdown) {
    barGraphData = arrServiceGraphData.map((serviceGraphData) => {
      const serviceGraphDataRequired = serviceGraphData[withWithoutProduction][periodType];
      return {
        name: serviceGraphData.name,
        labels: serviceGraphDataRequired.date,
        values: serviceGraphDataRequired[barType as keyof typeof serviceGraphDataRequired],
      };
    });
    barGraphDataForSummedValues = [
      {
        name: '',
        labels: graphDataOfAPeriodSelected.date,
        values: graphDataOfAPeriodSelected[barType as keyof typeof graphDataOfAPeriodSelected],
      },
    ];
  } else {
    barGraphData = [
      {
        name: '',
        labels: graphDataOfAPeriodSelected.date,
        values: graphDataOfAPeriodSelected[barType as keyof typeof graphDataOfAPeriodSelected],
      },
    ];
    barGraphDataForSummedValues = [
      {
        name: '',
        labels: graphDataOfAPeriodSelected.date,
        values: graphDataOfAPeriodSelected[barType as keyof typeof graphDataOfAPeriodSelected],
      },
    ];
  }
  barMaxYAxisValue = getMaxYAxis(barGraphDataForSummedValues[0].values as (number | null)[]);
  barGraphData = padLabelsAndValuesToCentraliseChart(barGraphData, MAX_NUMBER_OF_BARS_GENERAL);
  barGraphDataForSummedValues = padLabelsAndValuesToCentraliseChart(
    barGraphDataForSummedValues,
    MAX_NUMBER_OF_BARS_GENERAL
  );

  let lineGraphData;
  let lineMaxYAxisValue;
  if (lineType) {
    const graphDataRequired = graphData[withWithoutProduction][periodType];
    lineGraphData = [
      {
        name: '',
        labels: graphDataRequired.date,
        values: graphDataRequired[lineType as keyof typeof graphDataRequired],
      },
    ];
    lineGraphData = padLabelsAndValuesToCentraliseChart(lineGraphData, MAX_NUMBER_OF_BARS_GENERAL);
    lineMaxYAxisValue = getMaxYAxis(lineGraphData[0].values as (number | null)[]);
  }

  const graphPlotData = {
    bar: barGraphData,
    line: lineGraphData,
    barGraphDataForSummedValues,
    barMaxYAxisValue,
    lineMaxYAxisValue,
    barType,
    lineType,
    widthType,
    currency,
    isDisplayBarLegend,
  };
  return graphPlotData;
};

/**
 * Retrieve the required data from graphData and structure it in the format required by pptxgen addChart for station bar graph
 * @param graphData - Graph data to be structured in the format required by pptxgen addChart
 * @param graphDataAttributes - Attributes that determine what values to retrieve from graphData
 * @param currency - Currency
 * @returns graphPlotData
 */
const retrieveStationBarGraphPlotData = (
  graphData: StationGraphData,
  graphDataAttributes: {
    barType: 'weight' | 'cost';
    widthType: keyof typeof GRAPH_WIDTH_TYPE;
    isDisplayBarLegend: boolean;
  },
  serviceName: string,
  currency: string
) => {
  const { barType, widthType, isDisplayBarLegend } = graphDataAttributes;
  const graphPlotData = {
    bar: padLabelsAndValuesToCentraliseChart(
      [
        {
          name: serviceName,
          labels: graphData.station,
          values: graphData[barType as keyof typeof graphData],
        },
      ],
      MAX_NUMBER_OF_BARS_FOR_STATION
    ),
    barGraphDataForSummedValues: padLabelsAndValuesToCentraliseChart(
      [
        {
          name: serviceName,
          labels: graphData.station,
          values: graphData[barType as keyof typeof graphData],
        },
      ],
      MAX_NUMBER_OF_BARS_FOR_STATION
    ),
    barType,
    widthType,
    currency,
    isDisplayBarLegend,
  };
  return graphPlotData;
};

/**
 * This function holds the logic required to extract the locationData provided by the backend and plots them within
 * a predetermined slide order template structure
 * @param fetchGraphRes - Response from backend containing information required to be plot into the powerpoint slides
 * @param formState - Report form header values used for powerpoint slide headers
 * @param user - User provided by Auth0 SDK to place the user's email address in the closing slide
 */
const downloadPropertyMonthlyReviewPowerpoint = async (
  fetchGraphRes: any,
  monthlyReviewSlideMetadata: Array<SlideContent>,
  mapLocationNameByLocationId: Map<number, string>,
  mapServiceNameByServiceId: Map<number, string>,
  formState: {
    companyField: string;
    restaurantField: string;
    mapSetSelectedServiceIdBySelectedLocationId: Map<number, Set<number>>;
    dateRange: Array<DateObject>;
    isTagged: boolean | string | undefined;
    includeIssueSlides: boolean;
  },
  user: User | undefined
) => {
  const { arrLocationData, restaurantService, fileName } = fetchGraphRes;
  const { setLocationIdToGenerate, setServiceIdToGenerate, setGroupNameToGenerate } =
    restaurantService.arrLocationService.reduce(
      (
        {
          setLocationIdToGenerate,
          setServiceIdToGenerate,
          setGroupNameToGenerate,
        }: {
          setLocationIdToGenerate: Set<number>;
          setServiceIdToGenerate: Set<number>;
          setGroupNameToGenerate: Set<string>;
        },
        locationService: LocationService
      ) => {
        const { locationId, arrService } = locationService;
        setLocationIdToGenerate.add(locationId);
        arrService.forEach((service: Service) => {
          const { serviceId, group } = service;
          setServiceIdToGenerate.add(serviceId);
          if (group) setGroupNameToGenerate.add(group);
        });
        return { setLocationIdToGenerate, setServiceIdToGenerate, setGroupNameToGenerate };
      },
      {
        setLocationIdToGenerate: new Set(),
        setServiceIdToGenerate: new Set(),
        setGroupNameToGenerate: new Set(),
      }
    );
  const { dateRange, includeIssueSlides } = formState;
  // Map initialised for easier retrival during powerpoint generation
  const mapLocationDataByLocationId = new Map<number, LocationMonthlyReportData>(
    arrLocationData.map((locationData: LocationMonthlyReportData) => [
      locationData.locationId,
      locationData,
    ])
  );
  const restaurantName = restaurantService.name;
  const currency = restaurantService.arrLocationService[0].arrService[0].currency;
  // Powerpoint creation
  const pres = new Pptxgen();

  // Append starting slide
  const openingText = `${restaurantName}\nMonthly Review (${dateRange[1].format('MMMM-YYYY')})`;
  initialiseSlide(pres, SLIDE_STYLE.opening, openingText);

  // Append main body
  monthlyReviewSlideMetadata.forEach(({ headerText, locationId, serviceId, groupName }) => {
    if (
      (locationId !== null && !setLocationIdToGenerate.has(locationId)) ||
      (serviceId !== -1 && serviceId !== null && !setServiceIdToGenerate.has(serviceId)) ||
      (groupName !== null && !setGroupNameToGenerate.has(groupName))
    )
      return;
    const locationName = mapLocationNameByLocationId.get(locationId!)!;
    const serviceName = serviceId === null ? '' : mapServiceNameByServiceId.get(serviceId)!;

    const {
      graph: graphData,
      table: tableData,
      service: serviceData,
    } = mapLocationDataByLocationId.get(locationId!)!;
    const locationNameUpperCased = locationName.toUpperCase();
    const isNoGroup = tableData.byWeight.individual.length === tableData.byWeight.group.length;

    const {
      slideStyle,
      slideType,
      isExcludeForProduction,
      slideContentType,
      grouped,
      weightCostType,
      graphDataAttributes,
    } = SLIDE_ATTRIBUTE_BY_HEADER_TEXT[headerText];

    if (slideStyle === SLIDE_STYLE.section) {
      const sectionText = `${locationName}\n${headerText}`;
      initialiseSlide(pres, slideStyle, sectionText);
      return;
    } else if (slideStyle === SLIDE_STYLE.subSection && includeIssueSlides) {
      initialiseSlide(pres, slideStyle, headerText, locationNameUpperCased);
    } else if (slideStyle === SLIDE_STYLE.plain) {
      if (slideType === SLIDE_TYPE.graph) {
        // For location level graphs
        if (serviceId === -1) {
          let slide = initialiseSlide(pres, slideStyle, headerText, locationNameUpperCased);
          const graphPlotData = retrieveBarOrLineGraphPlotData(
            graphData.location,
            graphDataAttributes,
            currency
          );
          insertGraph(pres, slide, graphPlotData, headerText!);
        } else {
          const serviceGroupName = groupName || serviceName;
          const matchedServiceGroupGraphData = graphData.serviceGroup[serviceGroupName];
          if (!matchedServiceGroupGraphData) return; // If the group that was initially sorted and saved no longer exists
          const { isOnlyProduction, wastePerCoverSummary } = matchedServiceGroupGraphData;
          // If service is production, do not generate cover related slides
          // Note: Currently, weight and throw mixed graph is also not created for production service as the backend code does not generate daily graphs
          // for production service. Can be considered in future.
          if (isOnlyProduction && isExcludeForProduction) {
            return;
          }
          const graphPlotData = retrieveBarOrLineGraphPlotData(
            matchedServiceGroupGraphData,
            graphDataAttributes,
            currency
          );
          let slide = initialiseSlide(
            pres,
            slideStyle,
            `${serviceGroupName.toUpperCase()} - ${headerText}`,
            locationNameUpperCased
          );
          // SLIDE TYPE: GRAPH
          insertGraph(pres, slide, graphPlotData, headerText, wastePerCoverSummary);
        }
      } else if (
        slideType === SLIDE_TYPE.table &&
        slideContentType === SLIDE_CONTENT_TYPE.summaryTable
      ) {
        if (grouped && isNoGroup) {
          return;
        }
        let slide = initialiseSlide(pres, slideStyle, headerText, locationNameUpperCased);
        insertTable(pres, slide, headerText, {
          currency,
          arrReductionInWeightSummary: grouped
            ? tableData.byWeight.group
            : tableData.byWeight.individual,
          arrWastePerCoverSummary: grouped
            ? tableData.byWeightPerCover.group
            : tableData.byWeightPerCover.individual,
          arrReductionInCostSummary: grouped ? tableData.byCost.group : tableData.byCost.individual,
        });
      } else if (slideContentType === SLIDE_CONTENT_TYPE.stationAndMenuItem) {
        const {
          name,
          type,
          topStation: topStationData,
          topMenuItem: topMenuItemData,
        } = serviceData[serviceId!];
        // If service is plate waste, do not generate station slides
        if (
          type === 'Plate Waste' &&
          (headerText === 'TOP 3 LEFTOVER IN TERMS OF WEIGHT (BY STATIONS)' ||
            headerText === 'TOP 3 LEFTOVER IN TERMS OF COST (BY STATIONS)')
        ) {
          return;
        }
        let slide = initialiseSlide(
          pres,
          slideStyle,
          `${name.toUpperCase()} - ${headerText}`,
          locationNameUpperCased
        );
        // SLIDE TYPE: IMAGE
        if (slideType === SLIDE_TYPE.image) {
          const numberOfImagesToDisplay =
            type === 'Plate Waste'
              ? NUMBER_OF_IMAGES_DISPLAYED_FOR_PLATE_WASTE_SERVICE
              : NUMBER_OF_IMAGES_DISPLAYED_FOR_NON_PLATE_WASTE_SERVICE;
          const isWeight = weightCostType === WEIGHT_COST_TYPE.weight;
          insertImage(
            pres,
            slide,
            isWeight ? topMenuItemData.byWeight.finalMonth : topMenuItemData.byCost.finalMonth,
            `${name.toUpperCase()} - ${headerText}`,
            locationNameUpperCased,
            currency,
            numberOfImagesToDisplay,
            isWeight
          );
        } // SLIDE TYPE: STATION
        else if (slideType === SLIDE_TYPE.station) {
          if (type !== 'Plate Waste') {
            if (weightCostType === WEIGHT_COST_TYPE.weight) {
              // Only top 3 station is displayed
              const graphPlotData = retrieveStationBarGraphPlotData(
                topStationData.byWeight.graph!,
                graphDataAttributes,
                name,
                currency
              );
              insertStationGraph(pres, slide, graphPlotData);
              insertStationImage(slide, topStationData.byWeight.value);
            } else if (weightCostType === WEIGHT_COST_TYPE.cost) {
              // Only top 3 station is displayed
              const graphPlotData = retrieveStationBarGraphPlotData(
                topStationData.byCost.graph!,
                graphDataAttributes,
                name,
                currency
              );
              insertStationGraph(pres, slide, graphPlotData);
              insertStationImage(slide, topStationData.byCost.value, currency);
            }
          }
        }
        // SLIDE TYPE: TABLE
        else if (slideType === SLIDE_TYPE.table) {
          if (weightCostType === WEIGHT_COST_TYPE.weight) {
            insertServiceTop6MenuItemTable(
              slide,
              'Top 6 High Volume Items',
              currency,
              topMenuItemData.byWeight.finalMonth,
              topMenuItemData.byWeight.previousMonth,
              {
                isProductionService: type === 'Production',
                isWeight: weightCostType === WEIGHT_COST_TYPE.weight,
              }
            );
          } else if (weightCostType === WEIGHT_COST_TYPE.cost) {
            insertServiceTop6MenuItemTable(
              slide,
              'Top 6 High Value Items',
              currency,
              topMenuItemData.byCost.finalMonth,
              topMenuItemData.byCost.previousMonth,
              {
                isProductionService: type === 'Production',
                isWeight: weightCostType === WEIGHT_COST_TYPE.weight,
              }
            );
          }
        }
      } else if (includeIssueSlides) {
        const matchedServiceMonthlyReportData = serviceData[serviceId!];
        let arrIssueWasteAnalysis = [];
        if (headerText === 'SINGULAR THROWS') {
          arrIssueWasteAnalysis = [
            matchedServiceMonthlyReportData.issueWasteAnalysisByWeight.notInMenu,
          ];
        } else if (headerText === 'MIXED THROWS') {
          arrIssueWasteAnalysis = [
            matchedServiceMonthlyReportData.issueWasteAnalysisByWeight.mixed,
          ];
        } else {
          arrIssueWasteAnalysis = [
            matchedServiceMonthlyReportData.issueWasteAnalysisByWeight.usageIssue,
          ];
        }
        insertIssueImageSlideForAllServices(
          pres,
          headerText,
          locationNameUpperCased,
          arrIssueWasteAnalysis
        );
      }
    }
  });

  // Append closing slide
  initialiseSlide(pres, SLIDE_STYLE.closing, user?.email);

  await pres.writeFile({ fileName });
};

export default downloadPropertyMonthlyReviewPowerpoint;
