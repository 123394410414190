import DroppableContainer from './DroppableContainer';
import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  closestCenter,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Dispatch, SetStateAction, useState } from 'react';
import Slide from './Slide';
import { Column, SlideType } from './helper';
import { Button } from '@material-tailwind/react';

const EditMonthlyReviewSlide = (props: {
  monthlyReviewSlideMetadata: Array<SlideType>;
  setMonthlyReviewSlideMetadata: Dispatch<SetStateAction<Array<SlideType>>>;
  mapLocationNameByLocationId: Map<number, string>;
  mapServiceNameByServiceId: Map<number, string>;
}) => {
  const [arrSelectedSlideId, setArrSelectedSlideId] = useState<string[]>([]);
  const [activeSlide, setActiveSlide] = useState<SlideType | null>(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [previousSelectedSlideId, setPreviousSelectedSlideId] = useState<string | null>(null);

  // Below is code for mobile optimisation
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  /**
   * This function is called when a slide is selected
   * If a slide is selected with the shift key, then bulk selection is done based on the relative index and column
   * @param event - Mouse click event
   * @param selectedId - Id of slide selected
   */
  const selectSlide = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, selectedId: string) => {
    const isSelection = !arrSelectedSlideId.includes(selectedId);

    if (event.shiftKey && previousSelectedSlideId !== null) {
      const previousSelectedSlideIdInNumberFormat = Number(previousSelectedSlideId);
      const currentSelectedSlideIdInNumberFormat = Number(selectedId);
      const previousSelectedSlide = props.monthlyReviewSlideMetadata.find(
        (slide) => slide.id === previousSelectedSlideIdInNumberFormat
      );
      const currentSelectedSlide = props.monthlyReviewSlideMetadata.find(
        (slide) => slide.id === currentSelectedSlideIdInNumberFormat
      );
      if (previousSelectedSlide!.columnId !== currentSelectedSlide!.columnId) {
        setArrSelectedSlideId((selectedIds) => {
          if (isSelection) {
            return [...selectedIds, selectedId];
          } else {
            return selectedIds.filter((value) => value !== selectedId);
          }
        });
      } else {
        const isMultiSelection = arrSelectedSlideId.includes(previousSelectedSlideId);
        const setSlideIdToBeSelected: Set<string> = new Set();
        let isAccumulating = false;
        for (let slideId = 0; slideId < props.monthlyReviewSlideMetadata.length; slideId += 1) {
          const slide = props.monthlyReviewSlideMetadata[slideId];
          if (slide.columnId === previousSelectedSlide?.columnId && isAccumulating) {
            setSlideIdToBeSelected.add(`${slide.id}`);
          }
          if (
            slide.id === previousSelectedSlideIdInNumberFormat ||
            slide.id === currentSelectedSlideIdInNumberFormat
          ) {
            if (isAccumulating) {
              break;
            } else {
              isAccumulating = true;
              setSlideIdToBeSelected.add(`${slide.id}`);
            }
          }
        }
        setArrSelectedSlideId((prev) => {
          if (isMultiSelection && isSelection) {
            return Array.from(new Set([...prev, ...Array.from(setSlideIdToBeSelected)]));
          } else {
            return prev.filter((value) => !setSlideIdToBeSelected.has(value));
          }
        });
      }
    } else {
      setArrSelectedSlideId((prev) => {
        if (isSelection) {
          return [...prev, selectedId];
        } else {
          return prev.filter((value) => value !== selectedId);
        }
      });
    }
    setPreviousSelectedSlideId(selectedId);
  };

  /**
   * This function is called when a slide is dragged from their original positions
   * @param event - DragStartEvent that supplies the active data of the slide being dragged
   */
  const onDragStart = (event: DragStartEvent) => {
    const slideId = event.active.id.toString();
    // Find the index of the slide that's being dragged
    const slideIndex = props.monthlyReviewSlideMetadata.findIndex(
      (slide) => slide.id.toString() === slideId
    );

    // Determine if the slide is already selected
    const isSlideSelected = arrSelectedSlideId.includes(slideId);

    // Calculate yOffset only if the slide is already selected
    let yOffset = 0;
    if (isSlideSelected) {
      const firstSelectedIndex = props.monthlyReviewSlideMetadata.findIndex((slide) =>
        arrSelectedSlideId.includes(slide.id.toString())
      );
      yOffset = (slideIndex - firstSelectedIndex) * 42; // Offset of dragged element
    }

    setDragOffset({ x: 0, y: yOffset });

    // Select the slide if it's not already selected
    if (!isSlideSelected) {
      setArrSelectedSlideId([slideId]);
    }

    // Hide other selected slides if necessary
    props.setMonthlyReviewSlideMetadata((prev) =>
      prev.map((slide) => {
        if (
          arrSelectedSlideId.length > 1 &&
          arrSelectedSlideId.includes(slide.id.toString()) &&
          slide.id !== event.active.id
        ) {
          return { ...slide, hidden: true };
        } else {
          return slide;
        }
      })
    );

    // Set activeSlide for DragOverlay
    if (event.active.data.current?.type === 'Slide') {
      setActiveSlide(event.active.data.current.slide);
    }
  };

  /**
   * This function is called when element is dropped from being dragged
   * @param event - DragOverEvent that supplies the active and over data to modify the component state
   */
  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;
    const isActiveASlide = active.data.current?.type === 'Slide';
    const isOverASlide = over.data.current?.type === 'Slide';

    if (activeId === overId) return;
    if (!isActiveASlide) return;

    // Dropping a Slide over another Slide (sorting between slides)
    if (isActiveASlide && isOverASlide) {
      props.setMonthlyReviewSlideMetadata((arrSlide) => {
        const overIndex = arrSlide.findIndex((slide) => slide.id === overId);

        // Adjusted logic to reorder slides
        let updatedArrSlide = [...arrSlide];
        const movingArrSlide = updatedArrSlide.filter((slide) =>
          arrSelectedSlideId.includes(slide.id.toString())
        );

        // Remove selected slides from their current positions
        updatedArrSlide = updatedArrSlide.filter(
          (slide) => !arrSelectedSlideId.includes(slide.id.toString())
        );

        // Determine new index for insertion
        let newIndex = updatedArrSlide.findIndex((slide) => slide.id === overId);
        const initialIndex = newIndex;

        const overSlideIndex = arrSlide.findIndex((slide) => slide.id === overId);
        const activeSlideIndex = arrSlide.findIndex((slide) => slide.id === activeId);

        if (newIndex === -1) {
          // If overId not found in the list, calculate the index based on position
          newIndex = arrSlide.findIndex((slide) => slide.id === overId);
        } else {
          // If overId found, check if dragging below it, then increment newIndex
          if (activeSlideIndex > overSlideIndex) {
            // If dragging the slide downwards, decrement newIndex
            newIndex -= 1;
          }
        }

        if (activeSlideIndex === 0) {
          newIndex -= 1;
        }

        if (
          event.active.data?.current?.slide?.columnId !== event.over?.data?.current?.slide?.columnId
        ) {
          // Get the numeric values of active and over columnIds
          const activeColumnIdNumber = event.active.data?.current?.slide?.columnId;
          const overColumnIdNumber = event.over?.data?.current?.slide?.columnId;

          // Perform the comparison and adjust newIndex accordingly
          if (activeColumnIdNumber < overColumnIdNumber) {
            newIndex -= 1;
          }

          if (activeColumnIdNumber > overColumnIdNumber) {
            newIndex += 1;
          }

          if (
            event.active.data?.current?.slide?.columnId === Column.Unused &&
            event.over?.data?.current?.slide?.columnId !== Column.Unused
          ) {
            newIndex += 1;
          }

          if (
            event.active.data?.current?.slide?.columnId !== Column.Unused &&
            event.over?.data?.current?.slide?.columnId === Column.Unused
          ) {
            newIndex -= 1;
          }

          if (
            event.active.data?.current?.slide?.columnId !== Column.Current &&
            event.over?.data?.current?.slide?.columnId === Column.Current
          ) {
            if (initialIndex !== newIndex + 1) {
              newIndex -= 1;
            }
          }

          if (
            event.active.data?.current?.slide?.columnId === Column.Current &&
            event.over?.data?.current?.slide?.columnId !== Column.Current
          ) {
            if (initialIndex !== newIndex + 1) {
              newIndex -= 1;
            }
          }
        }

        // Correctly splice in the moving slides
        updatedArrSlide.splice(newIndex + 1, 0, ...movingArrSlide);

        // Update columnId for all slides being moved
        updatedArrSlide = updatedArrSlide.map((slide) => {
          if (arrSelectedSlideId.includes(slide.id.toString())) {
            return { ...slide, columnId: arrSlide[overIndex].columnId };
          }
          return slide;
        });

        return updatedArrSlide;
      });
    }

    const isOverAColumn = over.data.current?.type === 'Column';

    // Dropping a Slide over a column
    let useCol = overId as Column;

    if (isActiveASlide && isOverAColumn) {
      props.setMonthlyReviewSlideMetadata((arrSlide) => {
        const activeIndex = arrSlide.findIndex((slide) => slide.id === activeId);

        if (arrSelectedSlideId.length > 0) {
          const updatedArrSlide = arrSlide.map((slide) => {
            if (arrSelectedSlideId.includes(slide.id.toString())) {
              return { ...slide, columnId: useCol };
            }
            return slide;
          });

          return updatedArrSlide;
        } else {
          arrSlide[activeIndex].columnId = overId as Column;
          return arrayMove(arrSlide, activeIndex, activeIndex);
        }
      });
    }
  };

  /**
   * This function is called when the drag event ends
   */
  const onDragEnd = () => {
    setActiveSlide(null);
    setArrSelectedSlideId([]);
    props.setMonthlyReviewSlideMetadata((prev) =>
      prev.map((slide) => ({ ...slide, hidden: false }))
    );
  };

  /**
   * This function is called when the drag event is cancelled
   */
  const onDragCancel = () => {
    setActiveSlide(null);
  };

  /**
   * This function moves slides in bulk from 'Current' to 'Unused' or vice versa
   * @param columnId - Id of column to move slides to
   */
  const bulkMoveSlide = (columnId: Column) => {
    const updatedArrSlide = props.monthlyReviewSlideMetadata.map((slide) => {
      if (arrSelectedSlideId.includes(slide.id.toString())) {
        return { ...slide, columnId };
      }
      return slide;
    });
    props.setMonthlyReviewSlideMetadata(updatedArrSlide);
    setArrSelectedSlideId([]);
  };

  return (
    <div className="flex flex-col items-end w-full">
      <Button
        variant="gradient"
        color="deep-orange"
        className="w-fit"
        onClick={() => setArrSelectedSlideId([])}
      >
        Clear selection
      </Button>
      <div className="flex h-[28rem] w-full gap-2">
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
          onDragCancel={onDragCancel}
          collisionDetection={closestCenter}
        >
          <div className="flex-1">
            <div className="font-bold">
              <p>Current Slides</p>
            </div>
            <div className="border-2 p-2 rounded h-[95%] overflow-auto">
              <DroppableContainer
                key={Column.Current}
                columnId={Column.Current}
                arrSlide={props.monthlyReviewSlideMetadata.filter(
                  (slide) => slide.columnId === Column.Current
                )}
                arrSelectedSlideId={arrSelectedSlideId}
                selectSlide={selectSlide}
                count={arrSelectedSlideId.length}
                mapLocationNameByLocationId={props.mapLocationNameByLocationId}
                mapServiceNameByServiceId={props.mapServiceNameByServiceId}
              />
            </div>
          </div>
          <div className="flex justify-center gap-3 flex-col">
            <button
              className={`border rounded p-1 bg-gray-300 ${
                arrSelectedSlideId.length !== 0 ? 'hover:bg-gray-400 cursor-pointer' : ''
              } w-10`}
              onClick={() => bulkMoveSlide(Column.Unused)}
              disabled={arrSelectedSlideId.length === 0}
            >
              {'>'}
            </button>
            <button
              className={`border rounded p-1 bg-gray-300 ${
                arrSelectedSlideId.length !== 0 ? 'hover:bg-gray-400 cursor-pointer' : ''
              } w-10`}
              onClick={() => bulkMoveSlide(Column.Current)}
              disabled={arrSelectedSlideId.length === 0}
            >
              {'<'}
            </button>
          </div>
          <div className="flex-1">
            <div className="font-bold">
              <p>Unused Slides</p>
            </div>
            <div className="border-2 p-2 rounded h-[95%] overflow-auto">
              <DroppableContainer
                key={Column.Unused}
                columnId={Column.Unused}
                arrSlide={props.monthlyReviewSlideMetadata.filter(
                  (slide) => slide.columnId === Column.Unused
                )}
                arrSelectedSlideId={arrSelectedSlideId}
                selectSlide={selectSlide}
                count={arrSelectedSlideId.length}
                mapLocationNameByLocationId={props.mapLocationNameByLocationId}
                mapServiceNameByServiceId={props.mapServiceNameByServiceId}
              />
            </div>
          </div>
          <DragOverlay>
            {activeSlide ? (
              <div style={{ transform: `translateY(${dragOffset.y}px)` }}>
                <Slide
                  selected={arrSelectedSlideId.includes(activeSlide.id.toString())}
                  slide={activeSlide}
                  count={arrSelectedSlideId.length}
                  isDragging
                  mapLocationNameByLocationId={props.mapLocationNameByLocationId}
                  mapServiceNameByServiceId={props.mapServiceNameByServiceId}
                />
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  );
};

export default EditMonthlyReviewSlide;
