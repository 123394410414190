import axios from 'axios';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from '@material-tailwind/react';

import EditMonthlyReviewSlide from './EditMonthlyReviewSlide/EditMonthlyReviewSlide';
import CompanyContext from '../../context/CompanyContext';
import {
  Column,
  getDefaultPropertyMonthlyReviewSlideMetadata,
  SlideContent,
  SlideType,
} from './EditMonthlyReviewSlide/helper';

/**
 * This function compares the default (full applicable slide list) to the existing slide list already sorted by the user.
 * Note: If the user has not yet done any sorting, then the full applicable slide list is taken by default
 * Thereafter, each slide content object is initialised to fit the structure required by the drag and sort sorting columns.
 * @param defaultPropertyMonthlyReviewSlideMetadata - Array of default property monthly review slide metadata
 * @param existingPropertyMonthlyReviewSlideMetadata - Array of current property monthly review slide metadata
 * @returns Array of slide objects to the used by the drag and drop sorting component
 */
const initialisePropertyMonthlyReviewSlideMetadataToRender = (
  defaultPropertyMonthlyReviewSlideMetadata: Array<SlideContent>,
  existingPropertyMonthlyReviewSlideMetadata: Array<SlideContent> | null
) => {
  if (!defaultPropertyMonthlyReviewSlideMetadata) return [];
  // If existingPropertyMonthlyReviewSlideMetadata is null, there is no saved ordering yet from the user
  if (existingPropertyMonthlyReviewSlideMetadata === null) {
    return defaultPropertyMonthlyReviewSlideMetadata.map((obj, index) => {
      return {
        id: index + 1,
        columnId: Column.Current,
        hidden: false,
        content: obj,
      };
    });
  }
  // If there is a saved ordering, then split them into 'Current' and 'Unused' column, using the default as the full list of applicable slides
  const mapSlideContentBySlideContentString = new Map(
    defaultPropertyMonthlyReviewSlideMetadata.map((slideContent) => {
      const { headerText, locationId, serviceId, groupName } = slideContent;
      const slideContentString = `${headerText}+${locationId}_${serviceId}_${groupName}`;
      return [slideContentString, slideContent];
    })
  );
  const propertyMonthlyReviewSlideMetadataToRender: Array<SlideType> = [];
  let id = 1;
  existingPropertyMonthlyReviewSlideMetadata.forEach((slideContent) => {
    const { headerText, locationId, serviceId, groupName } = slideContent;
    const slideContentString = `${headerText}+${locationId}_${serviceId}_${groupName}`;
    if (mapSlideContentBySlideContentString.has(slideContentString)) {
      propertyMonthlyReviewSlideMetadataToRender.push({
        id,
        columnId: Column.Current,
        hidden: false,
        content: slideContent,
      });
      mapSlideContentBySlideContentString.delete(slideContentString);
      id += 1;
    }
  });
  Array.from(mapSlideContentBySlideContentString.values()).forEach((slideContent) => {
    propertyMonthlyReviewSlideMetadataToRender.push({
      id,
      columnId: Column.Unused,
      hidden: false,
      content: slideContent,
    });
    id += 1;
  });
  return propertyMonthlyReviewSlideMetadataToRender;
};

/**
 * This function retrieves all the slide content that has been sorted into the 'Current Slides' column to be saved in database
 * @param monthlyReviewSlideMetadata - Array of slides from the drag and drop sorting component
 * @returns Array of current slide content to be saved in database
 */
const getCurrentMonthlyReviewSlideMetadata = (monthlyReviewSlideMetadata: Array<SlideType>) => {
  const currentMonthlySlideMetadata: Array<SlideContent> = [];
  monthlyReviewSlideMetadata.forEach((slide) => {
    if (slide.columnId === Column.Current) {
      currentMonthlySlideMetadata.push(slide.content);
    }
  });
  return currentMonthlySlideMetadata;
};

const EditMonthlyReviewSlideDialog = (props: {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  restaurantId: number | null;
}) => {
  const companyContext = useContext(CompanyContext);
  const [monthlyReviewSlideMetadata, setMonthlyReviewSlideMetadata] = useState<Array<SlideType>>(
    []
  );
  const [mapLocationNameByLocationId, setMapLocationNameByLocationId] = useState<
    Map<number, string>
  >(new Map());
  const [mapServiceNameByServiceId, setMapServiceNameByServiceId] = useState<Map<number, string>>(
    new Map()
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const handleOpen = () => {
    if (errorMessage !== '') {
      setErrorMessage('');
    }
    props.setIsOpened(!props.isOpened);
  };

  /**
   * This function fetches the monthly review slide metadata of a property
   * After fetching, the metadata (if any) is transformed to the data structure required by the drag and drop sort component
   */
  const fetchMonthlyReviewSlideMetadata = async () => {
    const { arrCompanyService } = companyContext;
    const {
      defaultPropertyMonthlyReviewSlideMetadata,
      mapLocationNameByLocationId,
      mapServiceNameByServiceId,
    } = getDefaultPropertyMonthlyReviewSlideMetadata(arrCompanyService, props.restaurantId!)!;
    const response = await axios.post(
      '/report-management/fetch-property-monthly-review-slide-metadata',
      {
        restaurantId: props.restaurantId!,
      }
    );
    if (response.data && response.data.monthlyReviewSlideMetadata !== undefined) {
      setMonthlyReviewSlideMetadata(
        initialisePropertyMonthlyReviewSlideMetadataToRender(
          defaultPropertyMonthlyReviewSlideMetadata,
          response.data.monthlyReviewSlideMetadata
        )
      );
      setMapLocationNameByLocationId(mapLocationNameByLocationId);
      setMapServiceNameByServiceId(mapServiceNameByServiceId);
    }
  };

  useEffect(() => {
    if (props.isOpened && props.restaurantId !== null) {
      fetchMonthlyReviewSlideMetadata();
    }
  }, [props.restaurantId, props.isOpened]);

  /**
   * This function saves the current slide sort order to the database in restaurant table
   * @param isToResetToDefault - If true, monthly review slide metadata is set as null
   */
  const updateMonthlyReviewSlideMetadata = async (isToResetToDefault: boolean) => {
    const currentMonthlyReviewSlideMetadata = getCurrentMonthlyReviewSlideMetadata(
      monthlyReviewSlideMetadata
    );
    try {
      await axios.post('/report-management/update-property-monthly-review-slide-metadata', {
        restaurantId: props.restaurantId!,
        monthlyReviewSlideMetadata: isToResetToDefault ? null : currentMonthlyReviewSlideMetadata,
      });
      handleOpen();
    } catch (error) {
      setErrorMessage(
        'Error while trying to update slide ordering. Do ensure that that there was a change in slide ordering and try again. If there are no changes, please press cancel.'
      );
    }
  };

  return (
    <Dialog
      open={props.isOpened}
      handler={handleOpen}
      size="xl"
      className="h-fit w-11/12 max-w-none"
    >
      <DialogHeader>Edit Monthly Review Slides</DialogHeader>
      <DialogBody className="overflow-scroll pt-0">
        <EditMonthlyReviewSlide
          monthlyReviewSlideMetadata={monthlyReviewSlideMetadata}
          setMonthlyReviewSlideMetadata={setMonthlyReviewSlideMetadata}
          mapLocationNameByLocationId={mapLocationNameByLocationId}
          mapServiceNameByServiceId={mapServiceNameByServiceId}
        />
      </DialogBody>
      <DialogFooter className="space-x-2 items-end gap-1 flex-row-reverse">
        <div className="flex flex-1 gap-3 justify-end">
          <Button variant="gradient" color="red" onClick={handleOpen}>
            Cancel
          </Button>
          <Button color="amber" onClick={() => updateMonthlyReviewSlideMetadata(true)}>
            Confirm revert to default sort
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => updateMonthlyReviewSlideMetadata(false)}
          >
            Confirm sort
          </Button>
        </div>
        {errorMessage !== '' ? (
          <span className="text-red-500 text-sm flex-wrap w-[38rem]">{errorMessage}</span>
        ) : (
          ''
        )}
      </DialogFooter>
    </Dialog>
  );
};

export default EditMonthlyReviewSlideDialog;
