import { SortableContext, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { useMemo } from 'react';
import SortableSlide from './SortableSlide';
import { Container } from './helper';

const DroppableContainer = ({
  arrSlide,
  count,
  columnId,
  selectSlide,
  arrSelectedSlideId,
  mapLocationNameByLocationId,
  mapServiceNameByServiceId,
}: Container) => {
  const { setNodeRef } = useSortable({
    id: columnId,
    data: {
      type: 'Column',
      columnId,
    },
  });

  const arrSlideId = useMemo(() => {
    return arrSlide.map((slide) => slide.id);
  }, [arrSlide]);

  return (
    <div ref={setNodeRef}>
      <SortableContext strategy={rectSortingStrategy} items={arrSlideId}>
        {arrSlide.map((slide) =>
          !slide.hidden ? (
            <SortableSlide
              onSelect={(event: React.MouseEvent<HTMLDivElement>) =>
                selectSlide(event, slide.id.toString())
              }
              selected={arrSelectedSlideId.includes(slide.id.toString())}
              id={slide.id.toString()}
              key={slide.id}
              slide={slide}
              count={count}
              mapLocationNameByLocationId={mapLocationNameByLocationId}
              mapServiceNameByServiceId={mapServiceNameByServiceId}
            />
          ) : null
        )}
      </SortableContext>
    </div>
  );
};

export default DroppableContainer;
